/* eslint-disable eqeqeq */
/*Fil med stöd funktioner för att hantera olika operationer som är återkommande*/

import { Customer } from "../../services/API/Customer";
import { Invoice } from "../../services/API/Invoices"
import { InternalInvoiceWithCsrdAndGroupId, InvoiceKey, InvoicesWithCsrdAndGroupId, StandardInvoiceReport } from "./editTypes"
import { Option } from 'react-bootstrap-typeahead/types/types';
import { Contract } from '../../services/API/Contracts';

type groupKey = keyof Pick<InvoicesWithCsrdAndGroupId,'rowSumExclMoms'>;
type invoiceKey = keyof Pick<Invoice,'priceExcMoms'> ;
type uninOfKeys = groupKey | invoiceKey;

const editUtils = {
    CalculateNewSumTotal:(report:StandardInvoiceReport,path:keyof InvoiceKey,priceProp:uninOfKeys) => {
        if(path in report.report ){
            const buff:StandardInvoiceReport = structuredClone(report) 
            if(path == 'invoices'){
                const arr:Invoice[] = structuredClone(buff.report[path]) as Invoice[]
                if(Array.isArray(arr)){
                    const accumulatedSumOfAllInvoices = arr?.reduce((acc:number,curr:Invoice ) => {
                        if(priceProp == 'priceExcMoms'){
                         return acc + curr[priceProp]
                        }
                        return acc
                       },0)
                       return accumulatedSumOfAllInvoices as number 
                }
            }
            else if(path == 'csrdGroup'){
                const arr:InvoicesWithCsrdAndGroupId[] = structuredClone(buff.report[path]) as InvoicesWithCsrdAndGroupId[]
                const accumulatedSumOfAllInvoices = arr?.reduce((acc:number,curr: InvoicesWithCsrdAndGroupId) => {
                    
                    if(priceProp == 'rowSumExclMoms' && typeof curr[priceProp] !== 'undefined'){
                     
                     return acc + curr[priceProp]   
                    }
                    return acc
                   },0)
                   return accumulatedSumOfAllInvoices as number ?? 0
            }
        }
        return 0
        
    },
    UpdateCustomerBasedOnReportType:<T extends object>(invoiceKey:keyof InvoiceKey,key:keyof T,item:T,customers:Customer[],contracts:Contract[],e:Option[]):T | undefined => {
        const buff:T =  structuredClone(item)
        console.log('INNE I ON CHANGE FÖR CHOICEFEILD',e)
        if(/*invoiceKey == 'invoices' &&*/ key !== 'contractId' && 'customerOrgId' in buff && 'customerName' in buff ){
            const customer =  customers.find(x => x.kndNamn== e[0] || x.kndOrgnr.toString() == e[0])
            buff['customerName'] = customer?.kndNamn == undefined ? '':customer.kndNamn
            buff['customerOrgId'] = customer?.kndOrgnr == undefined ? '':customer.kndOrgnr
            return buff
          }else if(/*invoiceKey == 'invoices' &&*/ key == 'contractId' && 'contractId' in buff ){
            const contract =  contracts.find(x => x.contractId == e[0])
            buff['contractId'] = contract?.contractId == undefined ? '' : contract.contractId
            //this.props.callBack(buff,index)
            return buff
          }
    },
    UpdateMainInvoiceWhenChangeOccursOnCsrdArticleRows:(report:StandardInvoiceReport,csrdRow:InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,customers:Customer[])=>{
       const buff:StandardInvoiceReport = structuredClone(report)
       let customerOrgId = '';
       let headId:number = -1;
       let matched = false;
      buff.report.csrdGroup =  buff.report.csrdGroup?.map((x:/*InvoicesWithCsrdAndGroupId*/InternalInvoiceWithCsrdAndGroupId) => {
        if(x.groupId == csrdRow.groupId){
           // oldInvoiceId = x.invoiceNumber
           customerOrgId = csrdRow.customerOrgId
            x.invoiceNumber = csrdRow.invoiceNumber
            x.billingDate = csrdRow.billingDate
            x.customerName = csrdRow.customerName
            x.customerOrgId = csrdRow.customerOrgId
            x.contractId = csrdRow.contractId
            if(!matched){
                headId = x.csrdConnectionId
                matched = true
            }
            return x
        }
        return x

       })

      const newInvoiceSum = buff.report.csrdGroup?.reduce((acc,curr) => {
        if(curr.invoiceNumber == csrdRow.invoiceNumber){

            return acc + curr.rowSumExclMoms
        }
        return acc
       },0) 
       const customerId = customers.find(x => x.kndOrgnr == +customerOrgId)
       
       const foundIndexForInvoice = buff.report.invoices?.find(x => 
        x.csrdConnectionId == headId)
       
       if(typeof foundIndexForInvoice !== 'undefined' && typeof newInvoiceSum !== 'undefined' && typeof customerId !== 'undefined'){ 
          foundIndexForInvoice.priceExcMoms = newInvoiceSum
          foundIndexForInvoice.priceIncMoms = newInvoiceSum * 0.25 +newInvoiceSum
          foundIndexForInvoice.contractId = csrdRow.contractId
          foundIndexForInvoice.customerName = csrdRow.customerName
          foundIndexForInvoice.customerOrgId = csrdRow.customerOrgId
          foundIndexForInvoice.billingDate = csrdRow.billingDate
          foundIndexForInvoice.invoiceNumber = csrdRow.invoiceNumber
          foundIndexForInvoice.dueDate = csrdRow.billingDate
          foundIndexForInvoice.sendDate = csrdRow.billingDate
          foundIndexForInvoice.customerId = customerId?.kndLopnr.toString()       
       }
       return buff
    }
    
    
}
export{
    editUtils
}