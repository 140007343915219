/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
/* eslint-disable eqeqeq */
import * as React from 'react'
import { EditIcons } from './EditIcons';
import StyledAccordion from '../Accordion/StyledAccordion';
import { Stack } from 'react-bootstrap';
import { AddedForPrint, ChangeContainer, DeletedForPrint, EditHistory, InternalCsrdFull, InvoiceReportChange, KickbackReportChange, UpdatedForPrint } from './editTypes';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import i18n from '../../i18n';
import { Customer } from '../../services/API/Customer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReadFriendlyNumber } from '../../lib/utils';
//import { SvgForStatisticReports } from '../DashboardComps/SvgIcons';
interface IDisplayHistoryProps{
    data:EditHistory[];
    customers:Customer[];
}
type TabKeySelector ={
    [key:string]:string;
}
type ExpandedRow ={
    [key:string]:boolean;
}
const DisplayHistory = ({data,customers}:IDisplayHistoryProps) => {
    const [selectedKey,setSelctedKey] = React.useState<TabKeySelector>({})
  const [expandedRow, setExpandedRow] = React.useState<ExpandedRow| null>(null);

  const handleRowClick = (index: number,rowId:number) => {
    const test = structuredClone(expandedRow)
    if(test !== null){
        Object.entries(test).forEach(([key,value]) => {
           if(key == `${index}.${rowId}`){
            test[`${index}.${rowId}`] = !test[`${index}.${rowId}`]
           }
        })
        setExpandedRow(test); 
    }
  };
   React.useEffect(() => {
    if(typeof data !== 'undefined'){
        buildTabSelector()
        buildExpandedRowDict()
    }
   },[])

   const buildTabSelector = () => {
     
    const res:TabKeySelector = data.reduce((curr:TabKeySelector,val) => {
        if(!curr[val.id]){
            curr[val.id] = Object.entries(val.changesThatWasMade)
            .find(x => {
                if(Array.isArray(x[1]) && x[1].length > 0){
                    return x
                }
                else if( x[0] == 'invoiceReportFieldsUpdated' && x[1] !== null){
                    return x
                }
                else if(x[0] == 'kickbackReportFieldsUpdated' &&  x[1] !== null){
                    return x
                }
            })?.[0] ?? ''
            return curr
        }
        return curr
    },{})
    setSelctedKey(res)
   }
   const buildExpandedRowDict = () => {
        if(typeof data !== 'undefined'){
          const res =  data.reduce((curr:ExpandedRow,val,index) => {
                    if(typeof val?.changesThatWasMade?.updated !== 'undefined'){
                        val?.changesThatWasMade?.updated.forEach(x => {
                            curr[`${index}.${x.rowId}`] = false
                        })
                        return curr
                    }
                    return curr
            },{})
            setExpandedRow(res)
        }
   }
    const createTabTitle = (key:string,rowId:string) => {
        const titles = {
            'added':<div className='tabTitle' >
            <i style={{margin:0,padding:0,fontStyle:'normal'}}>{i18n.t(`editReports.${key}`)}</i>
            <div className='initIcon'>
            <EditIcons.AddedRow height='22px' width='22px' fill={selectedKey[rowId] == 'added' ? 'rgb(7, 162, 53)':'rgb(7, 162, 53, 0.2)'}/>
            </div>
        </div>,
        'updated':<div className='tabTitle'>
        <i style={{margin:0,padding:0,fontStyle:'normal'}}>{i18n.t(`editReports.${key}`)}</i>
        <div className='initIcon'>
        <EditIcons.ChangedRow height='22px' width='22px' fill={selectedKey[rowId] == 'updated' ? 'rgb(248, 176, 51)': 'rgb(248, 176, 51, 0.2)'}/>
        </div>
    </div>,
        'deleted':<div className='tabTitle'>
        <i style={{margin:0,padding:0,fontStyle:'normal'}}>{i18n.t(`editReports.${key}`)}</i>
        <div className='initIcon'>
        <EditIcons.DeletedRow height='22px' width='22px' fill={selectedKey[rowId] == 'deleted' ? 'rgb(179, 4, 4)':'rgb(179, 4, 4, 0.2)'}/>
        </div>
    </div>,
    'invoiceReportFieldsUpdated':<div className='tabTitle'>
        <i style={{margin:0,padding:0,fontStyle:'normal'}}>{i18n.t(`editReports.${key}`)}</i>
        <div className='initIcon'>
            <EditIcons.StatisticsReport height="22px" width="22px" fill={selectedKey[rowId] == 'invoiceReportFieldsUpdated' ? 'rgb(89, 191, 235)':'rgb(89, 191, 235,0.2)'}/>
        </div>
        </div>,
    'kickbackReportChange':<div className='tabTitle'>
        <i style={{margin:0,padding:0,fontStyle:'normal'}}>
        {i18n.t(`editReports.${key}`)}
        </i>
        <div className='initIcon'>
            <EditIcons.KickbackReport width='22px' height='22px' fill={ selectedKey[rowId] == 'kickbackReportChange' ? 'rgb(86, 181, 58)' : 'rgb(86, 181, 58,0.2)'}/>
        </div>
        </div>
        }[key]
        return(
            <>
            {titles}
            </>
        )
    }
    const renderOldCell = (key:string,x:any) => {
       const parsedKey = key.charAt(0).toUpperCase() + key.slice(1);
      return  <span style={{display:'flex'}}><p style={{margin:0}}>{x.currentRowVersion[parsedKey] }</p>{typeof x?.properties.find((p:any) => p.field == key)?.value !== 'undefined' && typeof x?.properties.find((p:any) => p.field == key)?.value !== x.currentRowVersion[parsedKey] ? <div style={{marginLeft:10}}>
            <FontAwesomeIcon style={{color:'rgb(248, 176, 51)'}} icon='triangle-exclamation'/></div> : ''}</span>
    }
    const renderBodyBasedOnKey = (key:string,value:AddedForPrint | UpdatedForPrint | DeletedForPrint/*Invoice[] | RowToChange[]*/,indexForHistoryItem:number) => {
        
        const isCsrd  = value.type == 'updated' && value.updated.find(x => 'RowSumExclMoms' in  x.currentRowVersion)?.currentRowVersion 
        const oldAndNew:{[key:string]:any} ={}
        if(isCsrd){
            value.updated.reduce((acc:any,item) => {
                const oldCsrdFilterd =  value.previusReportVersion.CsrdRows.find((x:any) =>x.InvoiceNumber == item.currentRowVersion.InvoiceNumber &&   x.Id == item.rowId)
                
                if(!oldAndNew[`${item.currentRowVersion.InvoiceNumber}-${item.rowId}`] && 'RowSumExclMoms' in item.currentRowVersion){
                    oldAndNew[`${item.currentRowVersion.InvoiceNumber}-${item.rowId}`] ={old:oldCsrdFilterd,new:{}}
                    const newCsrd = structuredClone(oldCsrdFilterd)
                    item.properties.forEach((q) => {      
                        
                        const parsedKey = q.field.charAt(0).toUpperCase() + q.field.slice(1)
                        newCsrd[parsedKey] = q.value
                    })
                    oldAndNew[`${item.currentRowVersion.InvoiceNumber}-${item.rowId}`].new = newCsrd
                  }
                },{})
        }
        const body = (key:string,value:AddedForPrint | UpdatedForPrint | DeletedForPrint/*Invoice[]|RowToChange[]*/ ) => {
            switch(value.type){
                case 'added' :  return(
                    <>
                    {value.added.length > 0 &&
                    <>
                        <p>Skapade Fakturor</p>
                        <table className='datalistTable' style={{marginBottom:15}}>
                        <thead>
                            <tr>
                                <th>Kund</th>
                                <th>Kund OrgNr</th>
                                <th>AvtalsID</th>
                                <th>Fakturanummer</th>
                                <th>Datum</th>
                                <th>Summa Exkl.moms</th>
                            </tr>
                        </thead>
                        <tbody style={{background:'white'}}>
                           
                            {value?.added?.map((x:any) =>{
                                 
                                 return <tr>
                                    <td>{customers.find(q => q.kndOrgnr == x.customerOrgId)?.kndNamn}</td>
                                     <td>{x.customerOrgId}</td>
                                     <td>{x.contractId}</td>
                                     <td>{x.invoiceNumber}</td>
                                     <td>{x.billingDate}</td>
                                     <td>{ReadFriendlyNumber(x.priceExcMoms)}</td>
                                 </tr>
                             } 
                             )}           
                    </tbody>
                   </table>
                   </>
                    }
                    {value.csrdAdded.length > 0 &&
                    <>
                    <p>Skapade Artiklar</p>
                        <table className='datalistTable' style={{marginBottom:15}}>
                        <thead>
                            <tr>
                                <th>Artikel Nr.</th>
                                <th>Artikeltext</th>
                                <th>Mängd</th>
                                <th>Enhet</th>
                                <th>A-pris</th>
                                <th>Summa</th>
                            </tr>
                        </thead>
                        <tbody style={{background:'white'}}>
                           
                            {value?.csrdAdded?.map((x:InternalCsrdFull) =>{
                                 
                                 return <tr>
                                    {/* <td>{customers.find(q => q.kndOrgnr == x.customerOrgId)?.kndNamn}</td> */}
                                     <td>{x.articleNumber}</td>
                                     <td>{x.articleText}</td>
                                     <td>{x.articleQuantity}</td>
                                     <td>{x.articleQuantityType}</td>
                                     <td>{x.articlePricePerUnit}</td>
                                     <td>{ReadFriendlyNumber(x.rowSumExclMoms)}</td>
                                 </tr>
                             } 
                             )}
                           
                            
                           
                    </tbody>
                   </table>
                    </>
                    }
           
            </>
                )
              case 'deleted' : return(
                <>
                {value?.deleted?.length > 0 && 
                    <>
                    <p>Bortagna fakturor</p>
                    <table className='datalistTable' style={{marginBottom:15}}>
               <thead>
                   <tr>
                       <th>Kund</th>
                       <th>Kund OrgNr</th>
                       <th>AvtalsID</th>
                       <th>Fakturanummer</th>
                       <th>Datum</th>
                       <th>Summa Exkl.moms</th>
                   </tr>
               </thead>
               <tbody style={{background:'white'}}>
                  {/* {key == 'csrdAdded' && } */}
                
                   {value.deleted.map((x:any) =>{
                     
                       return <tr>
                          <td>{customers.find(q => q.kndOrgnr == x.customerOrgId)?.kndNamn}</td>
                           <td>{x.customerOrgId}</td>
                           <td>{x.contractId}</td>
                           <td>{x.invoiceNumber}</td>
                           <td>{x.billingDate}</td>
                           <td>{ReadFriendlyNumber(x.priceExcMoms)}</td>
                       </tr>
                   } 
                   )}
                   <tr></tr>
                  
           </tbody>
          </table>
                    </>
                }
                {value?.csrdDeleted?.length > 0 && 
                <>
                  <p>Bortagna Artikelrader</p>
                  <table className='datalistTable' style={{marginBottom:15}}>
               <thead>
                   <tr>
                   <th>Artikel Nr.</th>
                                <th>Artikeltext</th>
                                <th>Mängd</th>
                                <th>Enhet</th>
                                <th>A-pris</th>
                                <th>Summa</th>
                   </tr>
               </thead>
               <tbody style={{background:'white'}}>
                  {/* {key == 'csrdAdded' && } */}
                
                   {value?.csrdDeleted?.map((x:InternalCsrdFull) =>{
                     
                       return <tr>
                          <td>{x.articleNumber}</td>
                                     <td>{x.articleText}</td>
                                     <td>{x.articleQuantity}</td>
                                     <td>{x.articleQuantityType}</td>
                                     <td>{x.articlePricePerUnit}</td>
                                     <td>{ReadFriendlyNumber(x.rowSumExclMoms)}</td>
                       </tr>
                   } 
                   )}
                   <tr></tr>
                  
           </tbody>
          </table>
                </>
                }
                
          </>
              )
                
               
              case  'updated':return(   
                <>
                {isCsrd ? <>
                <table className='datalistTable' style={{marginBottom:15}}>
                    <thead>
                    <tr>
                        <th ></th>
                        <th>Artikel Nr.</th>
                                <th>Artikeltext</th>
                                <th>Mängd</th>
                                <th>Enhet</th>
                                <th>A-pris</th>
                                <th>Summa</th>
                   
                    </tr>    
                    </thead>
                    <tbody>
                       {Object.keys(oldAndNew).map((x,index) => {
                        const isExpanded = expandedRow?.[`${indexForHistoryItem}.${oldAndNew[x].new.Id}`]
                        return (
                            <React.Fragment key={index}>
                        <tr style={{ cursor: 'pointer' }} onClick={() => handleRowClick(indexForHistoryItem,oldAndNew[x].new.Id)}>
                        <td> <button className='btn btn-primary'>  {isExpanded ?  <FontAwesomeIcon fontSize={12} icon='arrow-up'/>:<FontAwesomeIcon fontSize={12} icon='arrow-down'/> }</button></td>
                            <td>{oldAndNew[x].new.ArticleNumber}</td>
                            <td>{oldAndNew[x].new.ArticleText}</td>
                            <td>{oldAndNew[x].new.ArticleQuantity}</td>
                            <td>{oldAndNew[x].new.ArticleQuantityType}</td>
                           
                           <td>{oldAndNew[x].new.ArticlePricePerUnit}</td>
                            <td>{oldAndNew[x].new.RowSumExclMoms}</td>
                        </tr>
                        {isExpanded&& (
                            <tr className='child-row'>
                                <td></td>
                                <td>{oldAndNew[x].old.ArticleNumber}</td>
                            <td>{oldAndNew[x].old.ArticleText}</td>
                            <td>{oldAndNew[x].old.ArticleQuantity}</td>
                            <td>{oldAndNew[x].old.ArticleQuantityType}</td>
                           
                           <td>{oldAndNew[x].old.ArticlePricePerUnit}</td>
                            <td>{oldAndNew[x].old.RowSumExclMoms}</td>
                            </tr>
                        )}
                        </React.Fragment>
                        )
                       })}             
                    </tbody>
                </table>               
                </>               
                :               
                <table className='datalistTable' style={{marginBottom:15}}>
                <thead>
                    <tr>
                        <th ></th>
                        <th>Kund</th>
                        <th>Kund OrgNr</th>
                        <th>AvtalsID</th>
                        <th>Fakturanummer</th>
                        <th>Datum</th>
                        <th>Summa Exkl.moms</th>
                    </tr>
                </thead>
                <tbody style={{background:'white'}}>
              
                    {value.updated.map((x:any,index:number) =>{                      
                      const customer = customers.find(q => q.kndOrgnr === x.currentRowVersion.CustomerOrgId);
                      const newCustomer = customers.find(q => q.kndNamn == x?.properties.find((p:any) => p.field == 'customerName')?.value)?.kndNamn
                      const isExpanded = expandedRow?.[`${indexForHistoryItem}.${x.rowId}`] /*=== x.rowId*/;
                      return (
                        <React.Fragment key={index}>                         
                          <tr style={{ cursor: 'pointer' }} onClick={() => handleRowClick(indexForHistoryItem,x.rowId)}>
                            <td ><button className='btn btn-primary'>  {isExpanded ?  <FontAwesomeIcon fontSize={12} icon='arrow-up'/>:<FontAwesomeIcon fontSize={12} icon='arrow-down'/> }</button></td>
                            <td>{customers.find(q => q.kndNamn == x?.properties.find((p:any) => p.field == 'customerName')?.value)?.kndNamn ??customer?.kndNamn}</td>
                            <td>{x?.properties.find((p:any) => p.field == 'customerOrgId')?.value ?? x.currentRowVersion.CustomerOrgId }</td>
                            <td>{x?.properties.find((p:any) => p.field == 'contractId')?.value ?? x.currentRowVersion.ContractId} </td>
                            <td>{x?.properties.find((p:any) => p.field == 'invoiceNumber')?.value ?? x.currentRowVersion.InvoiceNumber }</td>
                            <td>{x?.properties.find((p:any) => p.field == 'billingDate')?.value ?? x.currentRowVersion.BillingDate}</td>
                            <td>{x?.properties.find((p:any) => p.field == 'priceExcMoms')?.value ?? x.currentRowVersion.PriceExcMoms}</td>
                          </tr>
            
                          
                          {isExpanded && (
                            <tr className='child-row'>
                            <td></td>
                            <td style={{fontStyle:'italic'}}>{typeof newCustomer !== 'undefined' && newCustomer !== customer?.kndNamn ? <span style={{display:'flex'}}><p style={{margin:0}}>{customer?.kndNamn}</p><div style={{marginLeft:10}}>
                            <FontAwesomeIcon style={{color:'rgb(248, 176, 51)'}} icon='triangle-exclamation'/></div></span> :customer?.kndNamn}</td>
                            <td style={{fontStyle:'italic'}}>{renderOldCell('customerOrgId',x)}</td>
                            <td style={{fontStyle:'italic'}}>{renderOldCell('contractId',x)}</td>
                            <td style={{fontStyle:'italic'}}>{renderOldCell('invoiceNumber',x)}</td>
                            <td style={{fontStyle:'italic'}}>{renderOldCell('billingDate',x)}</td>
                            <td style={{fontStyle:'italic'}}>
                                {renderOldCell('priceExcMoms',x)}
                                </td>
                          </tr>
                          )}
                        </React.Fragment>
                      );
                    } 
                    )}
            </tbody>
           </table>
                }
                
           </>
           )

            }
        }
        return(<>{body(key,value)}</>)
    }
    const createTabsTheBigPayBack = (key:string,value:EditHistory/*Invoice[]|RowToChange[] | InternalCsrdFull[]*/,rowId:string,indexForHistoryItem:number) => {
        const parsedData:{[key:string]:AddedForPrint | UpdatedForPrint | DeletedForPrint} = {
            'added':{type:'added',added:value.changesThatWasMade.added,csrdAdded:value.changesThatWasMade.csrdAdded,previusReportVersion:value} as AddedForPrint,
            'updated':{type:'updated',updated:value.changesThatWasMade.updated,previusReportVersion:value.previusReportVersion} as UpdatedForPrint,     
            'deleted':{type:'deleted',deleted:value.changesThatWasMade.deleted,csrdDeleted:value.changesThatWasMade.csrdDeleted,previusReportVersion:value.previusReportVersion} as DeletedForPrint
        }
        return(
            <Tab eventKey={key} title={createTabTitle(key,rowId)} >
             {renderBodyBasedOnKey(key,parsedData[key],indexForHistoryItem)}   
            </Tab>
        )
    }
    const countChanges = (changes:ChangeContainer) => {
        let counter = 0;
        Object.values(changes).forEach(x => {
            if(Array.isArray(x)){
                counter += x.length
            }
            else{              
                if(x !== null &&  ('kickback' in x && x['kickback'] !== null)){
                    counter += x['kickback'].length; 
                }
                if(x !== null &&  ('kickbackProject' in x && x['kickbackProject'] !== null)){
                    counter += x['kickbackProject'].length;
                }
                if(x !== null &&  ('properties' in x && x['properties'] !== null)){
                    counter += x['properties'].length;
                }
            }
        })
        return counter;
    }
    const renderBodyForInvoiceReportOrKickbackReport = (key:string,value:InvoiceReportChange | KickbackReportChange,indexForHistoryItem:number,historyItemForFullScope:EditHistory) => {
        const body = (key:string,value:InvoiceReportChange | KickbackReportChange) => {
            if(key == 'invoiceReportFieldsUpdated' && 'properties' in value && 'oldValues' in value){
                return(
                    <div className='invoiceReportCardContainer' >
                        <div className='invoiceReportCardContentContainer' style={{border:'2px solid rgb(89, 191, 235)'}}>
                        <div className='leftSidePanel' style={{backgroundColor:'rgb(89, 191, 235,0.6)'}}>
                        <EditIcons.StatisticsReport height="50px" width="50px" fill={ 'rgb(89, 191, 235)'}/>
                        </div>
                        <div className='rightSideContainer' >
                            <h1 className='invoiceReportCardContentTitle' style={{textDecoration:'underline rgb(89, 191, 235)'}}>Aktuella värden</h1>
                            {value.properties.map(x => 
                            <div className='invoiceReportTextArea'>
                                {x.field == 'Comment' ? 
                                <div className='invoiceReportComment'>
                                    <p className='invoiceReportTranslationField'>{i18n.t(`editReports.${x.field}`)}</p>
                                {/* <textarea readOnly value={x.value}/> */}
                                <div className='invoiceReportTextAreaStyling'>
                                 {x.value}
                                </div>
                                </div>
                                : 
                                <>
                                    <p className='invoiceReportTranslationField'>{i18n.t(`editReports.${x.field}`)}</p>
                                
                                <p className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(89, 191, 235)'}}>{x.field == 'reported' ? ReadFriendlyNumber(x.value):x.value}</p>
                                </>
                                }
                                
                                </div>)}
                            </div>
                            </div>

                            <div className='invoiceReportCardContentContainer' style={{border:'2px solid rgb(248, 176, 51)'}}>
                            <div className='leftSidePanel' style={{backgroundColor:'rgb(248, 176, 51,0.2)'}}>
                            <EditIcons.History width='50px' height='50px' fill='black'/>
                            </div>
                        <div className='rightSideContainer'>
                            <h1 className='invoiceReportCardContentTitle' style={{textDecoration:'underline rgb(248, 176, 51)'}}>Historiska värden</h1>
                            {value.oldValues.map(x => 
                            <div className='invoiceReportTextArea'>
                                {x.field == 'Comment' ? 
                                <div className='invoiceReportComment'>
                                    <p className='invoiceReportTranslationField'>{i18n.t(`editReports.${x.field}`)}</p>
                                {/* <textarea readOnly value={x.value}/> */}
                                <div className='invoiceReportTextAreaStyling'>
                                 {x.value}
                                </div>
                                </div>
                                : 
                                <>
                                    <p  className='invoiceReportTranslationField'>{i18n.t(`editReports.${x.field}`)}</p>
                                <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(248, 176, 51)'}}>{x.value}</p>
                                </>
                                }
                                </div>)}
                            </div>
                            </div>
                    </div>
                )
            }
            else if(key == 'kickbackReportChange' && 'kickback' in value && value['kickback'] !== null ){
                
                const currKick:any = value.kickback[0] as any
                
                return(
                    <div className='invoiceReportCardContainer' >
                        <div className='invoiceReportCardContentContainer' style={{border:'2px solid rgb(7, 162, 53)'}}>
                            <div className='leftSidePanel' style={{backgroundColor:'rgb(7, 162, 53,0.4)'}}>
                            <EditIcons.KickbackReport width='50px' height='40px' fill='rgb(7, 162, 53)'/>
                            </div>
                            <div className='rightSideContainer' >
                            <h1 className='invoiceReportCardContentTitle' style={{textDecoration:'underline rgb(7, 162, 53)'}}>Aktuella värden</h1>
                            <div className='invoiceReportTextArea'>
                                <>
                            <p  className='invoiceReportTranslationField'>Kommentar</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(7, 162, 53)'}}>{currKick?.comment}</p>
                            </>
                            </div>
                            <div className='invoiceReportTextArea'>
                            <>
                            <p  className='invoiceReportTranslationField'>Kontrakt</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(7, 162, 53)'}}>{currKick?.contractId}</p>
                            </>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <>
                            <p  className='invoiceReportTranslationField'>Avdelning</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(7, 162, 53)'}}>{currKick?.departmentId}</p>
                            </>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <>
                            <p  className='invoiceReportTranslationField'>Period</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(7, 162, 53)'}}>{currKick?.period}</p>
                            </>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <>
                            <p  className='invoiceReportTranslationField'>Inrapporterad</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(7, 162, 53)'}}>{currKick?.reportDate}</p>
                            </>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <>
                            <p  className='invoiceReportTranslationField'>Avdelning</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(7, 162, 53)'}}>{currKick?.departmentId}</p>
                            </>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(7, 162, 53)'}}>{currKick?.reporter}</p>
                            </>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <>
                            <p  className='invoiceReportTranslationField'>Rapporterat</p>
                            
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(7, 162, 53)'}}>{currKick?.sumExclMoms}</p>
                            </>
                            </div>
                            </div>
                        </div>
                        <div className='invoiceReportCardContentContainer' style={{border:'2px solid rgb(248, 176, 51)'}}>
                            <div className='leftSidePanel' style={{backgroundColor:'rgb(248, 176, 51,0.2)'}}>
                                <EditIcons.History width='50px' height='50px' fill='black'/>
                            </div>
                            <div className='rightSideContainer' >
                            <h1 className='invoiceReportCardContentTitle' style={{textDecoration:'underline rgb(248, 176, 51)'}}>Historiska värden</h1>
                            <div className='invoiceReportTextArea'>

                            <p  className='invoiceReportTranslationField'>Kommentar</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(248, 176, 51)'}}>{historyItemForFullScope.previusReportVersion.Kickback[0].Comment}</p>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <p  className='invoiceReportTranslationField'>Kontrakt</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(248, 176, 51)'}}>{historyItemForFullScope.previusReportVersion.Kickback[0].ContractId}</p>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <p  className='invoiceReportTranslationField'>Avdelning</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(248, 176, 51)'}}>{historyItemForFullScope.previusReportVersion.Kickback[0].DepartmentId}</p>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <p  className='invoiceReportTranslationField'>Period</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(248, 176, 51)'}}>{historyItemForFullScope.previusReportVersion.Kickback[0].Period}</p>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <p  className='invoiceReportTranslationField'>Inrapporterad</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(248, 176, 51)'}}>{historyItemForFullScope.previusReportVersion.Kickback[0].ReportDate}</p>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <p  className='invoiceReportTranslationField'>Avdelning</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(248, 176, 51)'}}>{historyItemForFullScope.previusReportVersion.Kickback[0].DepartmentId}</p>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(248, 176, 51)'}}>{historyItemForFullScope.previusReportVersion.Kickback[0].Reporter}</p>
                            </div>
                            <div className='invoiceReportTextArea'>

                            <p  className='invoiceReportTranslationField'>Rapporterat</p>
                            <p  className='invoiceReportTranslationField' style={{textDecoration:'underline rgb(248, 176, 51)'}}>{historyItemForFullScope.previusReportVersion.Kickback[0].SumExclMoms}</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else if(key == 'kickbackReportChange' && 'kickbackProject' in value && value['kickbackProject'] !== null){
                return(
                    <div className='invoiceReportCardContainer' >
                        <div className='invoiceReportCardContentContainer' style={{border:'2px solid rgb(7, 162, 53)'}}>
                            <div className='leftSidePanel' style={{backgroundColor:'rgb(7, 162, 53,0.4)'}}>
                            <EditIcons.KickbackReport width='50px' height='40px' fill='rgb(7, 162, 53)'/>
                            </div>
                            <div className='rightSideContainer' >
                            <h1 className='invoiceReportCardContentTitle' style={{textDecoration:'underline rgb(7, 162, 53)'}}>Aktuella värden</h1>
                            {value['kickbackProject'].map((x, index) => (
                                <div key={index} className="dataRow">
                                    <div className="dataField"><strong>Avtal:</strong> {x?.contractId}</div>
                                    <div className="dataField"><strong>Projektnamn:</strong> {x?.projectName}</div>
                                    <div className="dataField"><strong>Fakturamärkning:</strong> {x?.invoiceIdentifier}</div>
                                    <div className="dataField"><strong>Fakturareferens:</strong> {x?.invoiceReference}</div>
                                    <div className="dataField"><strong>Rapporterat:</strong> {x?.sumExclMoms}</div>
                                    <div className="dataField"><strong>Total kickback rapporterat:</strong> {x?.kickbackSum}</div>
                                    <div className="dataField"><strong>Ev. ändringar kommentar:</strong> {x?.commentKickbackChange}</div>
                                    <div className="dataField"><strong>Projektslutdatum:</strong> {`${x?.projectEndDate}`}</div>
                                    <div className="dataField"><strong>Kommentar:</strong> {x?.comment}</div>
                                </div>
                            ))}
                            </div>
                            </div>
                            <div className='invoiceReportCardContentContainer' style={{border:'2px solid rgb(248, 176, 51)'}}>
                            <div className='leftSidePanel' style={{backgroundColor:'rgb(248, 176, 51,0.2)'}}>
                                <EditIcons.History width='50px' height='50px' fill='black'/>
                            </div>
                            <div className='rightSideContainer' >
                            <h1 className='invoiceReportCardContentTitle' style={{textDecoration:'underline rgb(248, 176, 51)'}}>Historiska värden</h1>
                            {historyItemForFullScope.previusReportVersion.KickbackProject.map((x:any, index:number) => (
                                <div key={index} className="dataRow">
                                    <div className="dataField"><strong>Avtal:</strong> {x?.ContractId}</div>
                                    <div className="dataField"><strong>Projektnamn:</strong> {x?.ProjectName}</div>
                                    <div className="dataField"><strong>Fakturamärkning:</strong> {x?.InvoiceIdentifier}</div>
                                    <div className="dataField"><strong>Fakturareferens:</strong> {x?.InvoiceReference}</div>
                                    <div className="dataField"><strong>Rapporterat:</strong> {x?.SumExclMoms}</div>
                                    <div className="dataField"><strong>Total kickback rapporterat:</strong> {x?.KickbackSum}</div>
                                    <div className="dataField"><strong>Ev. ändringar kommentar:</strong> {x?.CommentKickbackChange}</div>
                                    <div className="dataField"><strong>Projektslutdatum:</strong> {`${x?.ProjectEndDate}`}</div>
                                    <div className="dataField"><strong>Kommentar:</strong> {x?.Comment}</div>
                                </div>
                            ))}
                            </div>
                            </div>
                    </div>
                )
            }
        }
        return(
            <>{body(key,value)}</>
        )
    }
    const createInvoiceReportTabOrKickbackTab = (key:string,value:InvoiceReportChange | KickbackReportChange,historyId:string,historyItemForFullScope:EditHistory,indexForHistoryItem:number) => {
        return(
           <Tab eventKey={key} title={createTabTitle(key,historyId)} >
             {<div style={{boxSizing:'border-box',borderRadius:10}}>
                {renderBodyForInvoiceReportOrKickbackReport(key,value,indexForHistoryItem,historyItemForFullScope)}
                </div>}
            </Tab>
        )
    }
    const renderHistoryCards = (history:EditHistory,indexForHistoryItem:number) => {
         return(
            <StyledAccordion  style={{padding:'10px 0px -1px -1px',marginBottom:0,marginTop:0,width:'95%',border:'4px solid rgb(236, 236, 236)'}}
            title={
                <label className='title'  style={{marginLeft:0,width:'100%',fontSize:'0.9rem'}}>
             <EditIcons.Details height='35px' width='50px'/>  
             <p style={{margin:0}}>Ändrad av :</p>
            <i>{history.user.mailAdress}</i> 
             <p style={{margin:0}}>Antal ändringar :</p>
            <i>{countChanges(history.changesThatWasMade)}</i>
            <p style={{margin:0}}>Datum :</p><i>{history.changeDate.toLocaleDateString()}</i>
            </label>
            }
            >
                <div  className={'tabStyleOveride '+`tabStyleOveride-${selectedKey[history.id]}`}>
                <Tabs defaultActiveKey={undefined} activeKey={selectedKey[history.id]} onSelect={(k) => {
                    const currKeyObj = structuredClone(selectedKey)
                    currKeyObj[history.id] = k as string
                    setSelctedKey(currKeyObj)
                }} className="mb-3"
            justify>
                {(history.changesThatWasMade.added.length > 0 || history.changesThatWasMade.csrdAdded.length > 0) && 
                    createTabsTheBigPayBack('added',history,history.id,indexForHistoryItem)
                }
                {(history.changesThatWasMade.deleted.length > 0 || history.changesThatWasMade.csrdDeleted.length > 0) && 
                    createTabsTheBigPayBack('deleted',history,history.id,indexForHistoryItem)
                 }
                {history.changesThatWasMade.updated.length > 0 && 
                  createTabsTheBigPayBack('updated',history,history.id,indexForHistoryItem)
                 }
                {history.changesThatWasMade.invoiceReportFieldsUpdated &&
                     createInvoiceReportTabOrKickbackTab('invoiceReportFieldsUpdated',history.changesThatWasMade.invoiceReportFieldsUpdated,history.id,history,indexForHistoryItem)
                }
                
                { history.changesThatWasMade.kickbackReportChange !== null && (history.changesThatWasMade.kickbackReportChange?.kickback?.length > 0 || history.changesThatWasMade.kickbackReportChange?.kickbackProject?.length > 0) &&
                        createInvoiceReportTabOrKickbackTab('kickbackReportChange',history.changesThatWasMade.kickbackReportChange,history.id,history,indexForHistoryItem)
                }
                </Tabs>
                </div>
            </StyledAccordion>
            
            
        )
    }
 
    return(
        <>
        {typeof data !== 'undefined' && 
            <StyledAccordion style={{padding:'10px 0px 0px 0px',marginBottom:15}} title={
                <label className='title' style={{marginLeft:0,fontSize:'1.1rem'}}>
                <EditIcons.History height='35px' width='35px'/>
                <p style={{margin:0}}>Ändringshistorik</p>
                </label>
                }>
                    <Stack  gap={3} style={{marginBottom:15,maxHeight:'40vh',overflow:'scroll'}}>
                        {
                        data.map((x,index) => {
                            return <div
                            style={{display:'flex',justifyContent:'center',width:'100%'}}>{renderHistoryCards(x,index)}</div>
                        })
                        }
                    </Stack>                   
            </StyledAccordion>
        }
        </>
    )
}
export{
    DisplayHistory
}