import * as React from "react";
import { Trans, withTranslation } from "react-i18next";
import { DataService } from "../services/DataService";
import { GroupedProjectNameAndEndDate } from "../services/API/Kickback";
import Datalist, { ListColumn } from "../components/Datalist/Datalist";
import i18n from "../i18n";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  Button,
  Form,
  OverlayTrigger,
  Spinner,
  Stack,
  Tooltip,
} from "react-bootstrap";
import {
  CheckReportPermission,
  ReadFriendlyNumber,
  ReadPercentageForProjectReport,
  delayedAction,
  getActiveSupplier,
} from "../lib/utils";
import {
  faClone,
  faPaperPlane,
  faPlus,
  faTrash,
  faExclamation,
  faFolder,
  faWarning,
  faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditableTable, { GroupedCol } from "../components/EditableTable";
import StyledAccordion from "../components/Accordion/StyledAccordion";
import SubmitKickbackProject from "../components/modal/SubmitKickbackProject";
import ToasterMsg from "../components/Toaster/ToasterMsg";
import { StyledModal } from "../components/modal/StyledModal";
import StyledCheckbox from "../components/StyledCheckbox/StyledCheckbox";
import { ColumnDef } from "@tanstack/react-table";
import KickbackReportDuplicatesModal from "../components/modal/KickbackReportDuplicatesModal";
import NullReport from "../components/ReportPage/NullReport";
import FileUploadInput  from "../components/FileUploadInput";
import TemplateListModal from "../components/modal/TemplateListModal";
import { ProjectReportValidation,_helpers, generateRgb } from "../lib/ProjectReportValidation";
import {ProjectDateField, ProjectInputField} from '../components/fields/ProjectReportTypeAhead'
import { CompressedDepartmentReported, DepartmentFunctionArgs, DepartmentReportedResponse, funcHandleAlreadyReported } from "../services/API/Departments";
import { DepartmentReportedView } from "../components/Supplier/UserDepartmentComps/DepartmentReportedView";
import ReportNotice from "../components/ReportNotice/ReportNotice";

interface IKickbackProjectReportPageProps {}
interface IKickbackProjectReportPageState {
  duplicatesCheckModal:boolean;
  loading: boolean;
  period: string | null;
  periodsToReport: string[];
  instructionTexts:any[];
  expand: boolean;
  showHelp: boolean;
  optionsAvailable:OptionsAvailable;
  projectEndDatesAvailable: GroupedProjectNameAndEndDate;
  openAccordion: boolean;
  openSubmitModal: boolean;
  multiplePeriods: boolean;
  nothingToReport: boolean;
  infoCard: JSX.Element;
  showToasterMessage: boolean;
  toasterMessage: any;
  periodTestData: Period;
  customerDict: CustomerDict;
  contractDict: ContractDict;
  showClearModal:boolean;
  duplicates:Filter;
  filterdPeriod:ProjectInvoice[];
  showFileImportModal:boolean;
  templateListModal:boolean;
  departmentReported?:{[key:string]:CompressedDepartmentReported};
  reportingArticles:boolean;
  collapsedGroups:number[]; 
  editableColumns:ColumnDef<ProjectInvoice>[],
  dbDuplicates:ProjectInvoice[],
  rowEdit?:boolean;
 
}


export type AssortedFields = Pick<ProjectInvoice,'invoiceNumber' | 'comment'>
export type TypeAheadStateKeys = Pick<IKickbackProjectReportPageState,"customerDict" | "contractDict" | "optionsAvailable">
export type OptionsAvailable={
  [key:string]:string[]
}
export type Expression ={
  key:string,
  propertys:string[],
  // opertaion:string,
  value:boolean
}
export type ProjectInvoiceKeys =
  | "projectName"
  | "totalExclMoms"
  | "reference"
  | "tag"
  | "comment"
  //| "projectStartDate"
  | "projectEndDate"
  | "customerName"
  | "contractId"
  | "billingDate"
  | "customerOrgId"
  //| "dbDuplicateFound"
  | "invoiceNumber"
  |
  "articleNumber"|
   "articlePricePerUnit"|
   "articleQuantity"|
   "articleQuantityType"|
   "articleText"|
   "rowSumExclMoms"

export type ContractDict = {
  [contractId: string]: Contract;
};
export type CustomerDict = {
  [customerOrgId: string]: {customerOrgId:string,customerName:string,customerId:string};
};

export type Period = {
  [period: string]: ProjectInvoice[];
};

export type Contract = {
  kickbackPercent: string;
  name: string;
  title: string;
};
export type InvalidRowColors = {
  [color:number]:string
}

export type TypeAheadKeys = Pick<ProjectInvoice,"projectName" | "customerName" | "customerOrgId" | "contractId" | "tag" | "reference">
export type ArticleKeys = Pick<ProjectInvoice,'articleNumber'|'articlePricePerUnit'|'articleQuantity'|'articleQuantityType'|'articleText'|'rowSumExclMoms'>
export interface ProjectInvoice  {
  //index: number;
  id:number;
  groupId:number;
  contractId: string;
  invoiceNumber:string;
  customerName: string;
  customerOrgId: string;
  customerId:string;
  projectName: string;
  totalExclMoms: { value: number; display: string; valid: boolean ,error:boolean};
  reference: string;
  comment: string;
  tag: string;
  projectEndDate: string /*| null*/;
  uniqueRowId: number;
  updateProjektName: boolean;
  supplierOrgId: string;
  kick: number;
  billingDate: string;
  expectedSum: number;
  reportedSum: number;
  isDuplicate: {value:boolean,backgorundColor:string};
  dbDuplicateFound:boolean;
   articleNumber:string
   articlePricePerUnit:string
   articleQuantity:string
   articleQuantityType:string
   articleText:string
   rowSumExclMoms:string      
};
interface ProjectInvoiceGrouped extends ProjectInvoice {
  items:ProjectInvoice[]
}
export type Filter ={
  [key:string]:boolean;
}
class KickbackProjectReportPage extends React.Component<
  IKickbackProjectReportPageProps,
  IKickbackProjectReportPageState
> {
  constructor(props: IKickbackProjectReportPageProps) {
    super(props);

    this.state = {
      loading: true,
      period: "",
      periodsToReport: [],
      expand: true,
      showHelp: false,
      optionsAvailable:{},
      projectEndDatesAvailable: {},
      openAccordion: false,
      openSubmitModal: false,
      multiplePeriods: false,
      nothingToReport: false,
      infoCard: <></>,
      showToasterMessage: false,
      toasterMessage: undefined,
      periodTestData: {},
      customerDict: {},
      contractDict: {},
      showClearModal:false,
      duplicates:{},
      filterdPeriod:[],
      instructionTexts:[],
      duplicatesCheckModal:false,
      showFileImportModal:false,
      templateListModal:false,
      reportingArticles:false,
      collapsedGroups:[],
      editableColumns:[],
      rowEdit:false,
      dbDuplicates:[]
    };
  }
  invalidColors:InvalidRowColors= { 
    1: 'rgb(248, 176, 53,0.2)',
    2: 'rgb(183, 248, 53,0.2)',
    3: 'rgb(53, 232, 220,0.2)',
    4: 'rgb(209, 17, 145,0.2)',
    5: 'rgb(250, 221, 0,0.2)',
    6: 'rgb(125, 248, 53,0.2)',
    7: 'rgb(53, 73, 248,0.2)',
    8: 'rgb(248, 53, 160,0.2)',
    9: 'rgb(238, 53, 248,0.2)',
    10:'rgb(29, 1, 64,0.2)',
    11: 'rgb(133, 24, 5,0.2)'
  }


  sumTableColumns:ListColumn<ProjectInvoice>[] = [
    {
      fieldName: "projectName",
      isActive: true,
      key: "projectName",
      minWidth: 100,
      name: `${i18n.t(
        "kickbackProject.projectName"
      )}` /*this.props.t("general.contract")*/,
      disabled: true,
      onRender: (item, col) => {
        return item.projectName;
      },
    },
    {
      fieldName: "totalExclMoms",
      isActive: true,
      key: "totalExclMoms",
      minWidth: 100,
      name: `${i18n.t(
        "kickbackProject.reported"
      )}` /*this.props.t("general.contract")*/,
      disabled: true,
      footer: (items) => {
        const tot = items.reduce((acc, item) => {
          return acc + item.totalExclMoms.value;
        }, 0);
        return <>{ReadFriendlyNumber(tot)}</>;
      } /*FooterOptions.SUM*/,
      onRender: (item, col) => {
        return <>{ReadFriendlyNumber(item.totalExclMoms.value)}</>;
      },
    },
    {
      fieldName: "totalKickBack",
      isActive: true,
      key: "totalKickBack",
      minWidth: 100,
      name: `${i18n.t(
        "kickbackProject.totalKickback"
      )}` /*this.props.t("general.contract")*/,
      disabled: true,
      footer: (items) => {
        const tot: string = items
          .reduce((acc, item) => {
            return acc + item.totalExclMoms.value * item.kick;
          }, 0)
          .toFixed(2);
        return (
          <>
            <i>{i18n.t("kickbackProject.longLine")} : </i>
            <b>{ReadFriendlyNumber(+tot)}</b>
          </>
        );
      },

      onRender: (item, col) => {
        return (
          <>
            {i18n.t("kickbackProject.kickFor")}{" "}
            {`${item.contractId}`}{" "}
            {`(${ReadPercentageForProjectReport(item.kick)}%)`} :{" "}
            {ReadFriendlyNumber(item.totalExclMoms.value * item.kick)}
          </>
        );
      },
    },
  ]
  private groupedColumns: GroupedCol<ProjectInvoice>[] = [
    {
      id: "articleNumber",
      header: i18n.t("invoiceReport.table.headers.articleNr"),
      cell: (props) => this._cell(props, "articleNumber"),
      minSize: "100px"
    },
    {
      id: "articleText",
      header: i18n.t("invoiceReport.table.headers.articleText"),
      cell: (props) => this._cell(props, "articleText"),
      minSize: "100px"
    },
    {
      id: "articleQuantity",
      header: i18n.t("invoiceReport.table.headers.articleQuantity"),
      cell: (props) => this._cell(props, "articleQuantity"),
      minSize: "50px"
    },
    {
      id: "articleQuantityType",
      header: i18n.t("invoiceReport.table.headers.articleQuantityType"),
      cell: (props) => this._cell(props, "articleQuantityType"),
      minSize: "30px"
    },
    {
      id: "articlePricePerUnit",
      header: i18n.t("invoiceReport.table.headers.articlePricePerUnit"),
      cell: (props) => this._cell(props, "articlePricePerUnit"),
      minSize: "100px"
    },
    {
      id: "rowSumExclMoms",
      header: i18n.t("invoiceReport.table.headers.rowSumExclMoms"),
      minSize: "100px",
      cell: (props) => this._cell(props, "rowSumExclMoms"),
    },
    {
      id: "nestedTools",
      minSize:"5%",
     header: "",
     cell: (row, index) => {
       return <Button 
         className="button outline-button-danger row-btn" 
         style={{ width: "100%" }}
         onClick={(ev) => {              
          const heehe = {...this.state.periodTestData,[this.state.period as string]:this.state.periodTestData[this.state.period as string].filter((item, i) => /*item.groupId == row.groupId &&*/ item.uniqueRowId !== row.uniqueRowId).sort((a,b) =>{ 
            if(a.groupId < b.groupId){
              return -1
            }
            if(a.groupId > b.groupId){
              return 1
            }
            return 0
           })}
          this.setState({periodTestData:heehe})
         }}>
         <FontAwesomeIcon icon={faTrash} />
       </Button>
     },
     footer: (col, groupId) => {
      return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} 
        onClick={() => {
          const stateAsOffNow: Period = _helpers.deepClone(this.state.periodTestData);
          const ind = this.state.periodTestData[this.state.period as string]
            .map(inv => inv.groupId)
            .lastIndexOf(groupId);
          if(ind == -1)
            return;
          const item = this.state.periodTestData[this.state.period as string][ind];
          const rowToAdd = {
            id:item.id + 1,
            contractId: item.contractId,
            uniqueRowId: Math.random(),
            projectName: item.projectName,
            totalExclMoms: item.totalExclMoms,
            reference: item.reference,
            tag: item.tag,
            projectEndDate: item.projectEndDate,
            groupId:item.groupId,
            customerName: item.customerName,
            customerOrgId: item.customerOrgId,
            billingDate: item.billingDate,
            isDuplicate: {value:false,backgorundColor:''},
            customerId:item.customerId,
            supplierOrgId: item.supplierOrgId,
            dbDuplicateFound:item.dbDuplicateFound,
            invoiceNumber:item.invoiceNumber,
            articleNumber:'',
            articlePricePerUnit:'',
            articleQuantity:'',
            articleQuantityType:'',
            articleText:'',
            rowSumExclMoms:''
          } as ProjectInvoice
          stateAsOffNow[this.state.period as string].push(rowToAdd)
          this.setState({periodTestData:stateAsOffNow})
        }}>
        <FontAwesomeIcon icon={faPlus}/>
        {i18n.t("invoiceReport.newArticleText")}
      </Button>
    },
     meta: "skipPaddingOnFooter"
   }
  ]

  async componentDidMount(): Promise<void> {
    await CheckReportPermission(window.location.href)
  
   
    const instructionTexts = this.getInstructionTexts()
    const test =await DataService.Kickback.GetTypeaheadListsForKickbackProjectReports();
    const customerDict = _helpers.createCustomerDict(test[4]);
    
    const contractDict = _helpers.createContractDict(test[5]);
    
    const handleProjectEndDates = _helpers.handleHistoricProjectEndDates(
      test[3],
      test[5]
    );
    const articleReporter = await DataService.InvoiceReports.IsActiveSupplierTestingReport()
    const editRow = await DataService.InvoiceReports.ActiveSupplierEditRow()
    const columns = this.returnColumnDefsBasedOnReportingArticleAttribue(articleReporter)
    const duplicates:Filter = {'duplicates':false,'warnings':false,'errors':false}
    
    this.setState({
      optionsAvailable: {
        "projectName": test[0],
        "reference": test[1],
        "tag": test[2],
      },
      projectEndDatesAvailable: handleProjectEndDates,
      customerDict,
      contractDict,
      duplicates,
      instructionTexts,
      editableColumns:columns,
      reportingArticles: articleReporter,
      rowEdit:editRow
      
    });
    if (typeof test == "undefined") {
      this.renderInfoCard("ERROR");
    }
    else{

      this.parseInvoiceData();
    }
  }

  private returnColumnDefsBasedOnReportingArticleAttribue = (val:boolean):ColumnDef<ProjectInvoice>[] => {
   
    return val ? ( [
      {
        id: "projectName",
        header: `${i18n.t("kickbackProject.projectName")}`,
        cell: (props) => this._cell(props.row.original, "projectName"),
        minSize: 150,
        footer:(props) => {
          
          return(
           `${i18n.t('kickbackProject.TotalPosts')}: ${this.state.periodTestData[this.state.period as string].length}`
           
          )
        }
       
      },
      {
        id: "customerName",
        header: `${i18n.t("kickbackProject.customerName")}`,
        cell: (props) => this._cell(props.row.original, "customerName"),
        minSize: 125,
      
      },
      {
        id: "customerOrgId",
        header: `${i18n.t("kickbackProject.customerOrgId")}`,
        cell: (props) => this._cell(props.row.original, "customerOrgId"),
        minSize: 140,
      
      },
      {
        id: "contractId",
        header: `${i18n.t("kickbackProject.contractId")}`,
        cell: (props) => this._cell(props.row.original, "contractId"),
        minSize: 125,
      },
      {
        id: "invoiceNumber",
        header: `${i18n.t("kickbackProject.invoiceNumber")}`,
        cell: (props) => this._cell(props.row.original, "invoiceNumber"),
        minSize: 125,
      },
      {
        id: "totalExclMoms",
        header: `${i18n.t("kickbackProject.sum")}`,
        cell: (props) => this._cell(props.row.original, "totalExclMoms"),
        // minSize: 170,
        footer:(props) => {
          const total = this.state.periodTestData[this.state.period as string].reduce((total,item) => {
            return total = total + +item.rowSumExclMoms
          },0)
          return(
            `${i18n.t('kickbackProject.Totals')}: ${ReadFriendlyNumber(total)}`
          )
        }
         
      },
      {
        id: "billingDate",
        header:
         `${i18n.t('kickbackProject.billingDate')}` /*`${i18n.t('kickbackProject.startDate')}`*/,
        cell: (props) => this._cell(props.row.original, "billingDate")
      },
    
      {
        id: "reference",
        header: `${i18n.t("kickbackProject.invoiceReference")}`,
        cell: (props) => this._cell(props.row.original, "reference"),
      },
  
      {
        id: "tag",
        header: `${i18n.t("kickbackProject.invoiceLabel")}`,
  
        cell: (props) => this._cell(props.row.original, "tag"),
      },
     
      {
        id: "projectEndDate",
        header: `${i18n.t("kickbackProject.endDate")}`,
        cell: (props) => this._cell(props.row.original, "projectEndDate")
      },
      {
        id: "comment",
        header: `${i18n.t("kickbackProject.comment")}`,
        cell: (props) => this._cell(props.row.original, "comment"),
        minSize: 170,
      },
     
      {
        id:"noOfArticles",
        header: () => i18n.t('invoiceReport.table.headers.noOfArticles'),
        minSize:40,
        maxSize:40,
        cell: (props) => {
          const groupItems = this.state.periodTestData[this.state.period as string].filter(inv => inv.groupId == props.row.original.groupId);
          return this.state.reportingArticles ?  <div className="disabled-cell">
            {
                groupItems.length
            }
          </div>: <></>
        }
      },
      {
        id:"warnings/errors",
        header: () => "",
        minSize:25,
        maxSize:25,
        cell: (props) => {
          const groupItems = this.state.periodTestData[this.state.period as string].filter(inv => {return inv.groupId == props.row.original.groupId});
          const {errors,warnings} =_helpers.isValidArticleGroup(groupItems)
          let icon = errors  ? faWarning : faCircleCheck;
          let color = "#07a235";
          if(errors > 0) color = "#b30404";
          else if(warnings > 0) color = "#f8b133";
          return <>{
          this.state.reportingArticles ? 
            <Stack direction="horizontal" gap={2} style={{height: "100%",justifyContent: "center"}}>
                <FontAwesomeIcon icon={icon} color={color} style={{height: "60%", marginRight: 6, marginLeft: 6}}/>
            </Stack>
            : <></>
          }</>
        }
      },
      {
        accessorKey: "none",
        minSize:100,
        header: () => i18n.t("invoiceReport.table.headers.tools"),
        cell: (props) => {
          return (
            <Stack
              direction="horizontal"
              style={{
                height: 40,
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="outline-button row-btn"
                onClick={(ev) => {
                  this.cloneRow(props.row.original);
                }}
              >
                <FontAwesomeIcon icon={faClone} />
              </Button>
              <Button
                className="button outline-button-danger row-btn"
                onClick={(ev) => {
                  this.handleRowDelete(props.row.original);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Stack>
          );
        },
        meta: "skipPaddingOnFooter",
        footer: () => {
            return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} onClick={() => this.addRow()}>
              <FontAwesomeIcon icon={faPlus}/>
              {i18n.t('invoiceReport.table.footers.add')}
            </Button>
        },
      },
    ]) : ( [
      {
        id: "projectName",
        header: `${i18n.t("kickbackProject.projectName")}`,
        cell: (props) => this._cell(props.row.original, "projectName"),
        minSize: 150,
        footer:(props) => {
          
          return(
           `${i18n.t('kickbackProject.TotalPosts')}: ${this.state.periodTestData[this.state.period as string].length}`
           
          )
        }
       
      },
      {
        id: "customerName",
        header: `${i18n.t("kickbackProject.customerName")}`,
        cell: (props) => this._cell(props.row.original, "customerName"),
        minSize: 125,
      
      },
      {
        id: "customerOrgId",
        header: `${i18n.t("kickbackProject.customerOrgId")}`,
        cell: (props) => this._cell(props.row.original, "customerOrgId"),
        minSize: 140,
      
      },
      {
        id: "contractId",
        header: `${i18n.t("kickbackProject.contractId")}`,
        cell: (props) => this._cell(props.row.original, "contractId"),
        minSize: 125,
      },
      {
        id: "invoiceNumber",
        header: `${i18n.t("kickbackProject.invoiceNumber")}`,
        cell: (props) => this._cell(props.row.original, "invoiceNumber"),
        minSize: 125,
      },
      {
        id: "totalExclMoms",
        header: `${i18n.t("kickbackProject.sum")}`,
        cell: (props) => this._cell(props.row.original, "totalExclMoms"),
        // minSize: 170,
        footer:(props) => {
          const total = this.state.periodTestData[this.state.period as string].reduce((total,item) => {
            return total = total + item.totalExclMoms.value
          },0)
          return(
            `${i18n.t('kickbackProject.Totals')}: ${ReadFriendlyNumber(total)}`
          )
        }
         
      },
      {
        id: "billingDate",
        header:
         `${i18n.t('kickbackProject.billingDate')}` /*`${i18n.t('kickbackProject.startDate')}`*/,
        cell: (props) => this._cell(props.row.original, "billingDate")
      },
    
      {
        id: "reference",
        header: `${i18n.t("kickbackProject.invoiceReference")}`,
        cell: (props) => this._cell(props.row.original, "reference"),
      },
  
      {
        id: "tag",
        header: `${i18n.t("kickbackProject.invoiceLabel")}`,
  
        cell: (props) => this._cell(props.row.original, "tag"),
      },
     
      {
        id: "projectEndDate",
        header: `${i18n.t("kickbackProject.endDate")}`,
        cell: (props) => this._cell(props.row.original, "projectEndDate")
      },
      {
        id: "comment",
        header: `${i18n.t("kickbackProject.comment")}`,
        cell: (props) => this._cell(props.row.original, "comment"),
        minSize: 170,
      },
     
     
      {
        accessorKey: "none",
        minSize:100,
        header: () => i18n.t("invoiceReport.table.headers.tools"),
        cell: (props) => {
          return (
            <Stack
              direction="horizontal"
              style={{
                height: 40,
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="outline-button row-btn"
                onClick={(ev) => {
                  this.cloneRow(props.row.original);
                }}
              >
                <FontAwesomeIcon icon={faClone} />
              </Button>
              <Button
                className="button outline-button-danger row-btn"
                onClick={(ev) => {
                  this.handleRowDelete(props.row.original);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Stack>
          );
        },
        meta: "skipPaddingOnFooter",
        footer: (props) => {
            return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} onClick={() => this.addRow()}>
              <FontAwesomeIcon icon={faPlus}/>
              {i18n.t('invoiceReport.table.footers.add')}
            </Button>
        },
      },
    ])
  }
  private parseInvoiceData = () => {
    const sessionData = sessionStorage.getItem(`${getActiveSupplier()}-projectReport`)
    if(sessionData !== null  ){
      const saved:Period = JSON.parse(sessionData)
      const val:ProjectInvoice[][] = Object.values(saved) 
      const langPeriods = _helpers.buildReportPeriods()
     const parsedPeriod:Period = langPeriods.reduce((parsedPeriod:Period,item:string,counter:number) => {
      if(!parsedPeriod[item]){
          parsedPeriod[item] = val[counter]
        }
        return parsedPeriod
      },{})
      
      this.setState(
        {
          periodTestData: parsedPeriod,
          periodsToReport: Object.keys(parsedPeriod),
          period: Object.keys(parsedPeriod)[Object.keys(parsedPeriod).length -2],
        },
        () => this.setState({ loading: false },async() => this.setState({departmentReported:await funcHandleAlreadyReported(2,langPeriods,i18n.language)}) )
      );
    }
    else{
      const test = _helpers.buildReportPeriods()
      const period: Period = test.reduce((period: Period, item: string) => {
        if (!period[item]) {
          period[item] = [];
        }
        return period;
      }, {});
      this.setState(
        {
          periodTestData: period,
          periodsToReport: test,
          period: test[test.length -2],
        },
        () => this.setState({ loading: false },async() => this.setState({departmentReported:await funcHandleAlreadyReported(2,test,i18n.language)}))
      );
    }
    
  };
  private handleDuplicateVerified = (verifiedData?:ProjectInvoice[],deletedRows?:number[]): void => {
    
    if(typeof verifiedData !== 'undefined' && typeof deletedRows !== 'undefined'){
      let current:Period = _helpers.deepClone(this.state.periodTestData) 
      const stateAsOffNow:ProjectInvoice[] = _helpers.deepClone(current[this.state.period as string])
      let removedRows:ProjectInvoice[] = []
      
      for (let index = 0; index < stateAsOffNow.length; index++) {
       if(deletedRows.length > 0){
        for (let j = 0; j < deletedRows.length; j++) {
          if(deletedRows[j] !== stateAsOffNow[index].uniqueRowId){
              removedRows.push(stateAsOffNow[index])
          }
          
        }
       }
        else{
          removedRows = stateAsOffNow
        }
        
      }
      for (let index = 0; index < removedRows.length; index++) {
        for (let j = 0; j < verifiedData.length; j++) {
          if(verifiedData[j].uniqueRowId == removedRows[index].uniqueRowId){
              
            verifiedData[j].isDuplicate = {value:false,backgorundColor:''}
            removedRows[index] = verifiedData[j]
          }
        }
        
      }
      current[this.state.period as string] = removedRows
      this.setState({periodTestData:current,loading:true},() => this.setState({loading:false,duplicatesCheckModal:false,openSubmitModal:true}))
    }
    else{
      this.setState({duplicatesCheckModal:false,openSubmitModal:true})
    }
    

  }
  private handleSubmitModal = (): void => {
    
    this.setState((prevState) => ({
      openSubmitModal: !prevState.openSubmitModal,
      
    }));
  };


  private cloneRow = (row: any) => {
    let stateAsOfNow: Period = _helpers.deepClone(this.state.periodTestData);
    let copyToAdd: ProjectInvoice = _helpers.deepClone(row);
    copyToAdd.totalExclMoms = { value: 0, display: "", valid: false,error:true };
    copyToAdd.uniqueRowId = Math.random();
    copyToAdd.expectedSum = 0;
    copyToAdd.isDuplicate = {value:false,backgorundColor:''};
    copyToAdd.invoiceNumber = ''
    copyToAdd.billingDate = ''
    copyToAdd.groupId = Math.random()
    copyToAdd.kick = copyToAdd.contractId.length > 0 ? _helpers.convertToPercentage(this.state.contractDict[copyToAdd.contractId].kickbackPercent) : 0
    const currentElementIndex = stateAsOfNow[
      this.state.period as string
    ].findIndex((x) => x.uniqueRowId == row.uniqueRowId);
    stateAsOfNow[this.state.period as string].splice(
      currentElementIndex + 1,
      0,
      copyToAdd
    );
    this.setState(
      {
        periodTestData: stateAsOfNow,
      }
    );
  };

  private handleRowDelete = async (row: any) => {
    let stateAsOffNow: Period = _helpers.deepClone(this.state.periodTestData);
    stateAsOffNow[this.state.period as string] = stateAsOffNow[
      this.state.period as string
    ].filter((x) => this.state.reportingArticles ? (x.groupId !== row.groupId) : (x.uniqueRowId !== row.uniqueRowId));
  stateAsOffNow[this.state.period as string] =this.state.reportingArticles ?  _helpers.checkTemporaryDuplicates(stateAsOffNow[this.state.period as string],this.state.reportingArticles,this.state.dbDuplicates,this.state.duplicates['duplicates']) : await _helpers.checkDuplicates(stateAsOffNow[this.state.period as string],this.state.reportingArticles,this.state.dbDuplicates,this.state.duplicates['duplicates'])
    this.setState(
      { periodTestData: stateAsOffNow /*, invoicesParsed: invoices */ },
      () => this.checkAndUpdateSelectedContractAndPeriod()
    );
  };
  
  private handleRefOrCommentUpdate = (rowId: number, key:keyof TypeAheadKeys): void => {
    const dataToUpdate: Period = _helpers.deepClone(this.state.periodTestData);
    let name = dataToUpdate[this.state.period as string].find(
      (x) => x.uniqueRowId == rowId
    );
    if (typeof name !== "undefined" && (key !== 'customerName' && key !== 'customerOrgId')) {
      name[key] = "";
      this.setState({ periodTestData: dataToUpdate });  
    } 
    else if(typeof name !== "undefined" && (key == 'customerName' || key == 'customerOrgId')){
      name["customerName"] = ""
      name["customerOrgId"] = ""
      dataToUpdate[this.state.period as string] = _helpers.checkDuplicates(dataToUpdate[this.state.period as string],this.state.reportingArticles,this.state.dbDuplicates,this.state.duplicates['duplicates'])
      this.setState({periodTestData: dataToUpdate})
    }
  };
  private updateDateObject = (
    contractId: string,
    projectName: string,
    date: string
  ): void => {
    
    if (date.length > 0) {
      let current: GroupedProjectNameAndEndDate = _helpers.deepClone(
        this.state.projectEndDatesAvailable
      );
      current[contractId][projectName] = date;
      this.setState(
        {
          projectEndDatesAvailable: current,
        } 
      );
    }
  };

  private catchEmallEndDateHandler = (rowIndex: number,date?: string): void => {
    let projectEndDates: GroupedProjectNameAndEndDate = _helpers.deepClone(  this.state.projectEndDatesAvailable)
    let stateAsOfNow:Period = _helpers.deepClone(this.state.periodTestData);

    let rowUpdated = stateAsOfNow[this.state.period as string].find(
      (x) => x.uniqueRowId == rowIndex
    );
    if (
      typeof rowUpdated?.projectName !== "undefined" &&
      typeof rowUpdated.contractId !== "undefined"
    ) {
      if (
        rowUpdated.projectName.length > 0 &&
        rowUpdated.contractId.length > 0
      ) {
        if (
          typeof projectEndDates[rowUpdated.contractId][
            rowUpdated.projectName
          ] == "undefined"
        ) {
          if (rowUpdated.projectEndDate.length > 0) {
            projectEndDates[rowUpdated.contractId][
              rowUpdated.projectName
            ] = rowUpdated.projectEndDate;
            rowUpdated.projectEndDate = "";
            this.setState({ projectEndDatesAvailable: projectEndDates });
          }
        } else {
          projectEndDates[rowUpdated.contractId][
            rowUpdated.projectName
          ] = rowUpdated.projectEndDate;
          this.setState({ projectEndDatesAvailable: projectEndDates });
        }
      }
    }
  };
  private handleProjectEndDate = (date: Date | null, item: ProjectInvoice) => {
    const dataToUpdate: Period = _helpers.deepClone(this.state.periodTestData);
   
    if (item.projectName.length > 0 && item.contractId.length > 0)
     {
      dataToUpdate[this.state.period as string]
        .filter(
          (x) =>
            x.projectName == item?.projectName &&
            x.projectName.length > 0 &&
            x.contractId == item.contractId
        )
        .forEach((q) => (q.projectEndDate = date?.toLocaleDateString('sv-SE') ?? ""));
      this.setState(
        {
          periodTestData: dataToUpdate,
        },
        () => this.catchEmallEndDateHandler(item.uniqueRowId)
      );
    } else {
      if (typeof item !== "undefined") {
 let endDateToUpdate = dataToUpdate[this.state.period as string].find(
      (x) => x.uniqueRowId == item.uniqueRowId
    ) as ProjectInvoice;
        endDateToUpdate.projectEndDate = date?.toLocaleDateString('sv-SE') ?? "";
        this.setState({ periodTestData: dataToUpdate });
      }
    }
  };

  private showValidationInfo = (props: any, key: string): JSX.Element => {
    const validationText = {
      "totalExclMoms" : i18n.t("kickbackProject.numFieldValidation").toString() +" 0-9 , . -",
      "billingDate": i18n.t("kickbackProject.billingDateFieldValidation").toString(),
      "save": i18n.t('kickbackProject.infoSave').toString(),
      "invoiceNumber": i18n.t('kickbackProject.infoHistoricDuplicates').toString(),
      "duplicate": i18n.t('kickbackProject.infoHistoricDuplicates').toString(),
      "reference": i18n.t('kickbackProject.infoInvoiceReference').toString(),
      "tag": i18n.t('kickbackProject.infoInvoiceTag').toString(),
      "projectEndDate": i18n.t('kickbackProject.projectEndDate'),
      "rowSumExclMoms":i18n.t("kickbackProject.numFieldValidation").toString() +" 0-9 , . -",
      "articlePricePerUnit":i18n.t("kickbackProject.numFieldValidation").toString() +" 0-9 , . -",
      "articleQuantity":i18n.t("invoiceReport.generic.message"),     
      "articleQuantityType": i18n.t("invoiceReport.generic.message"),
      "articleText":i18n.t("invoiceReport.generic.message"),
      "articleNumber":i18n.t("invoiceReport.generic.message")
    }[key];
    return(
      <Tooltip id="button-tooltip" {...props}>
        <span>{validationText}</span>
      </Tooltip>
    )
  };

  private handleTypeaheadBlur = (value:string,rowId:number,key:ProjectInvoiceKeys):void => {
    let optionsAvailable:OptionsAvailable = _helpers.deepClone(this.state.optionsAvailable)
      optionsAvailable[key].find(x => x == value) ?? optionsAvailable[key].push(value)
    if(value.length > 0){
      let stateAsOffNow:Period = _helpers.deepClone(this.state.periodTestData)
      let rowToUpdate:ProjectInvoice | undefined =  this.findRow(stateAsOffNow,rowId)
      if(rowToUpdate){
        switch (key){
          case 'projectName':{
            rowToUpdate[key] = value
            break;
          }
          case 'reference':{
            rowToUpdate[key] = value
            break;
          }
          case 'tag':{
            rowToUpdate[key] = value
            break;
          }
          
        }
        this.setState({periodTestData:stateAsOffNow,optionsAvailable})
      }
      
    }
  }
private renderTypeAhead = (
  blur:Readonly<boolean>,
  item:Readonly<ProjectInvoice>,
  key:Readonly<keyof TypeAheadKeys>,
  stateKey:Readonly<keyof TypeAheadStateKeys>,
  allowNew:Readonly<boolean>,
  overlay:Readonly<boolean>,
  title:Readonly<string>,
  warningSeverity:Readonly<string>,
  placeHolder:Readonly<string>,
  className?:Readonly<string>,
  options?:string[],
  span?:boolean):JSX.Element => {
   
  return(
    <div
      className={`fieldWrapper ${item[key].length == 0 ? `${warningSeverity}`: ''}`}
      style={key == 'contractId' || key == 'customerName' || key == 'customerOrgId'  ? { backgroundColor: `${item.isDuplicate.backgorundColor}`} :{} }
  >
    {item[key].length == 0 ? (
      <>
        <Typeahead
          allowNew={allowNew}
          positionFixed
          placeholder={placeHolder}
          newSelectionPrefix={i18n.t("kickbackProject.add")+' '}
          emptyLabel={`${i18n.t("kickbackProject.emptyLabel")}`}
          id={`${item.uniqueRowId}`}
          options={options ?? this.state[stateKey][key] as string[]}
          onBlur={blur ? (e) => this.handleTypeaheadBlur(e.target.value,item.uniqueRowId,key): undefined }
          onChange={(e) =>
            this.onChangeContractUsers(item.uniqueRowId, e, key)
          }
        >
          {item[key]}
        </Typeahead>
              {item[key].length == 0 && (
                <FontAwesomeIcon
                className="exclamationIconStyle"
                  icon={faExclamation}
                />
              )}
      </>
    ) : (
      
      <div
        title={title}
        className={className ?? "projectNameDiv"}
        onClick={() =>
          this.handleRefOrCommentUpdate(item.uniqueRowId, key)
          
        }
      >
      {typeof span !== 'undefined' ?   
       <span
        className="contractSpan"
        >
                {" "}
                {item.contractId}-
                {this.state.contractDict[item.contractId].title}-
                {this.state.contractDict[item.contractId].name}
              </span>
      :  item[key]
      }  
      </div>
    )}
    {overlay && (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => this.showValidationInfo(props, key)}
      >
       {item[key].length == 0 ? (
                <FontAwesomeIcon
                className="exclamationIconStyle"
                  icon={faExclamation}
                />
              ):<>
              </>}
      </OverlayTrigger>
    )}
  </div>
);
  
}
  private _cell = (props: ProjectInvoice, key: ProjectInvoiceKeys) => {
    const item = props
      
    if (key == "totalExclMoms") {
      const total =  this.state.periodTestData[this.state.period as string].filter(x =>{return x.groupId == props.groupId}).reduce((acc,item) => {
        return acc + +item.rowSumExclMoms
    },0)
  
      const styling = {className:  `fieldWrapper ${
        this.state.reportingArticles ? (   isNaN(total)
          ? "fieldWrapper-error"
          : "") : (item.totalExclMoms.display.length == 0 || !item.totalExclMoms.valid ? "fieldWrapper-error" : '' )
      }`,
      style:item.isDuplicate.value == true
        ? { backgroundColor:`${item.isDuplicate.backgorundColor}`}
        : { backgroundColor: "" }
    }
    
  
    return this.state.reportingArticles ? ( <ProjectInputField divStyle={styling} overlay={isNaN(total)} value={total}
        placement="right"
        inputField={key}
        item={item}
        disabled={true}
        placeHolder={`${i18n.t('kickbackProject.fieldSumInput')}`}
        showValidationInfo={this.showValidationInfo}
    />) : (<ProjectInputField divStyle={styling} overlay={!item.totalExclMoms.valid} value={item[key].display}
      onChange={{key:'onChangeForOriginal',change:{onChangeForOriginal:this.onChangeContractUsers}}}
      inputField={key}
      item={item}
      placement="right"
      placeHolder={`${i18n.t('kickbackProject.fieldSumInput')}`}
      showValidationInfo={this.showValidationInfo}
  />)
    } 
    else if (key == "projectName") {
     return this.renderTypeAhead(true,item,key,"optionsAvailable",true,false,`${i18n.t("kickbackProject.changeProjectName")}`,"fieldWrapper-error",`${i18n.t('kickbackProject.projectNamePlaceholder')}`)
    } 
    else if (key == "comment") {
  
      return <ProjectInputField
      divStyle={{className:'fieldWrapper',style:{position:'relative'} }}
      placement="right"
      item={item}
      inputField={key}
      onChange={{key:'onChangeForInvTagComRef',change:{onChangeForInvTagComRef:this.onChangeForInvoiceNrComment}}}
      value={item[key]}
      overlay={false}
      placeHolder=""
      />
    } 
    else if (key == "reference") {
      return this.renderTypeAhead(true,item,key,"optionsAvailable",true,true,`${i18n.t("kickbackProject.changeProjectReferece")}`,"fieldWrapper-warn","")
    } 
    else if (key == "projectEndDate") {
      const overlay = item[key].length == 0
      const styling = {
        className:`fieldWrapper ${
          overlay && "fieldWrapper-warn"
        }`,
        style:
               { backgroundColor: "" ,width:'100%'}
      }
      return <ProjectDateField
      divStyle={styling}
      period={this.state.period as string}
      item={item}
      dateField={key}
      showValidationInfo={this.showValidationInfo}
      handleDatechange={this.handleProjectEndDate}
      overlay={overlay}
      minMax={false}
      />

    } 
    else if (key == "tag") {
      return this.renderTypeAhead(true,item,key,"optionsAvailable",true,true,`${i18n.t("kickbackProject.changeInvoiceLabel")}`,"fieldWrapper-warn","")
    } 
    else if (key == "customerName") {
      return this.renderTypeAhead(false,item,key,"optionsAvailable",false,false,`${i18n.t("kickbackProject.changeCustomer")}`,"fieldWrapper-error",`${i18n.t('kickbackProject.customerFieldValidation')}`,undefined,Object.values(this.state.customerDict).map(x =>  x.customerName))
    } 
    else if (key == "customerOrgId") {
      return this.renderTypeAhead(false,item,key,"optionsAvailable",false,false,`${i18n.t("kickbackProject.changeCustomerOrgId")}`,"fieldWrapper-error",`${i18n.t('kickbackProject.customerOrgFieldValidation')}`,undefined,Object.values(this.state.customerDict).map(x => x.customerOrgId))
    } 
    else if (key == "contractId") {
      return this.renderTypeAhead(false,item,key,"contractDict",false,false,`${i18n.t("kickbackProject.changeContractId")}`,"fieldWrapper-error",`${i18n.t('kickbackProject.contractIdFieldValidation')}`,undefined,Object.keys(this.state.contractDict),true)
    } 
    else if (key == "billingDate") {
      const overlay = item[key].length == 0
      const styling = {
        className:`fieldWrapper ${
          overlay && "fieldWrapper-error"
        }`,
        style:
          item.isDuplicate.value == true
              ? {  width:'100%',backgroundColor: `${item.isDuplicate.backgorundColor}`}
              : { backgroundColor: "",width:'100%' }
        
      }
      return <ProjectDateField
      divStyle={styling}
      period={this.state.period as string}
      item={item}
      dateField={key}
      showValidationInfo={this.showValidationInfo}
      handleDatechange={this.handleBillingDate}
      overlay={overlay}
      minMax={true}
      />
    }
    else if(key == "invoiceNumber"){
      const styling = {className:`fieldWrapper ${
        item.invoiceNumber.length == 0
          ? "fieldWrapper-error"
          : ''
         
      }`,
      style:item.isDuplicate.value == true
        ? { backgroundColor:`${item.isDuplicate.backgorundColor}`}
        : { backgroundColor: "" }
    }
      return <ProjectInputField divStyle={styling} value={item[key]} 
      onChange={{key:'onChangeForInvTagComRef',change:{onChangeForInvTagComRef:this.onChangeForInvoiceNrComment}}}
      placement="right"
      placeHolder={`${i18n.t('kickbackProject.fieldInvoiceNumberInput')}`}
      item={item}
      inputField={key}
      overlay={item.invoiceNumber.length == 0}
      showValidationInfo={this.showValidationInfo}
      />
    }
    
    else{
      const styling = {className:`fieldWrapper ${
        item[key].length == 0 && key !== 'articleNumber'
          ? "fieldWrapper-error"
          : ''
         
      }`
    }

      return(<ProjectInputField 
        divStyle={styling} 
        placement='bottom'
        placeHolder=""
        value={item[key]} 
        onChange={{key:'onChangeForArticle',change:{onChangeForArticle:this.updateArticle}}}
        item={item}
        inputField={key}
        overlay={item[key].length == 0 && key !== 'articleNumber'}
        showValidationInfo={this.showValidationInfo}
        />)
    }
    
  };
 private updateArticle = async(item:ProjectInvoice,e:any,key:Readonly<keyof ArticleKeys>):Promise<void> =>{
    const objToChange = this.state.periodTestData[this.state.period as string].indexOf(item,0)
    // const objToChange = this.state.periodTestData[this.state.period as string].findIndex((element)=>{
    //   const {['isDuplicate']:_,...filterdElement} = element;
    //   const {['isDuplicate']:__,...filterdItem} = item;
    //   return JSON.stringify(filterdElement) === JSON.stringify(filterdItem)
    // })
    // console.log('--------DATA--------',item,e,key,objToChange)
    if(typeof objToChange !== 'undefined'){  
    this.setState( prevState => { return prevState.periodTestData[this.state.period as string][objToChange][key] = e/*e.target.value*/} 
          ,() => key == 'rowSumExclMoms' && 
          this.setState(prevState => ({periodTestData:{...prevState.periodTestData,[this.state.period as string]:_helpers.checkTemporaryDuplicates(this.state.periodTestData[this.state.period as string],this.state.reportingArticles,this.state.dbDuplicates,this.state.duplicates['duplicates'])}}))) 
    }
  }
  private handleBillingDate = (e: Date | null, rowToUpdate: ProjectInvoice): void => {  
    if (
      (rowToUpdate.customerName.length > 0 ||
        rowToUpdate.customerOrgId.length > 0) &&
      rowToUpdate.contractId.length > 0 &&
      rowToUpdate.invoiceNumber.length > 0 &&
      rowToUpdate.projectName.length > 0
    ) {
      let statusAsOfNow: Period = _helpers.deepClone(this.state.periodTestData);
       let rowToUpdate1 = this.findRow(statusAsOfNow, rowToUpdate.uniqueRowId) as ProjectInvoice;
      //dessa loggar skall tas bort efter att datumbuggen är avklarad
       console.log(e)
       rowToUpdate1.billingDate = e?.toLocaleDateString("sv-SE",{year:'numeric',month:'2-digit',day:'2-digit'}) ?? "";
      console.log(rowToUpdate1.billingDate)
    statusAsOfNow[this.state.period as string] =  _helpers.checkDuplicates(statusAsOfNow[this.state.period as string],this.state.reportingArticles,this.state.dbDuplicates,this.state.duplicates['duplicates'])  
    this.setState({ periodTestData: statusAsOfNow })
    }
  };
  private addRow = () => {
    const stateAsOffNow: Period = _helpers.deepClone(this.state.periodTestData);
    
    const rowToAdd = {
      id:stateAsOffNow[this.state.period as string].length,
      contractId: "",
      uniqueRowId: Math.random(),
      projectName: "",
      totalExclMoms: { value: 0, display: "", valid: false ,error:true},
      reference: "",
      tag: "",
      projectEndDate: "",
      customerName: "",
      customerOrgId: "",
      billingDate: "",
      isDuplicate: {value:false,backgorundColor:''},
      customerId:'',
      supplierOrgId: getActiveSupplier(),
      dbDuplicateFound:true,
      invoiceNumber:'',
      articleNumber:'',
      articlePricePerUnit:'',
      articleQuantity:'',
      articleQuantityType:'',
      articleText:'',
      rowSumExclMoms:''
    } as ProjectInvoice
    rowToAdd.groupId = rowToAdd.uniqueRowId
    stateAsOffNow[this.state.period as string].push(rowToAdd);
    this.setState({ periodTestData: stateAsOffNow });
  };

  private createTabTest = (period?: string): JSX.Element => {
    const sumTable: JSX.Element[] = [];
    const selectedFilter = Object.values(this.state.duplicates).find(x => x == true)?.valueOf()
    let filter:ProjectInvoice[] = []
    if (
      typeof this.state.periodTestData[this.state.period as string] !==
      "undefined"
    ) {
      Object.keys(this.state.contractDict).forEach((x) =>
        sumTable.push(this.sumTable(x))
      );
    }
    if(Object.values(this.state.duplicates).find(x => x == true)?.valueOf() !== undefined){
      filter = _helpers.activeFilterRow(this.state.duplicates,this.state.period as string,this.state.periodTestData)
    }
    const items = typeof selectedFilter !== 'undefined' && filter.length > 0 ? filter : 
    typeof selectedFilter == 'undefined' &&  
   typeof this.state.periodTestData[this.state.period as string] !== 'undefined' ? 
   this.state.periodTestData[this.state.period as string] : []
   let groupedItems:ProjectInvoiceGrouped[] = []
   if(this.state.reportingArticles){
  groupedItems =   items.reduce((prev, curr) => {
      const potentialGroup = prev.find(p => p.groupId == curr.groupId);
      if(potentialGroup){
        potentialGroup.items = potentialGroup.items.concat(curr);
      }
      else {
        prev = prev.concat({ 
          ...curr,
          items: [curr]
         })
      }
      return prev;
    }, [] as ProjectInvoiceGrouped[]);
  }

    return (
      <>
        {typeof this.state.periodTestData[this.state.period as string] !==
          "undefined" && (
          <>
            <div className="statisticSection">
              <EditableTable
                columns={this.state.editableColumns/*ändra tillbaka till en class prop av cloumndefs när piloterna är klara*/ }
                grouped={this.state.reportingArticles ? {
                  groupedColumns: this.groupedColumns,
                  collapsedGroups: this.state.collapsedGroups,
                  onCollapseGroup: (groupId:number) => { 
                    const groupIdExists = this.state.collapsedGroups.includes(groupId);
                    const newList = groupIdExists ? 
                      this.state.collapsedGroups.filter(g => g != groupId)
                      :
                      this.state.collapsedGroups.concat(groupId);
                    this.setState({ collapsedGroups: newList });
                  },
                  onCollapseAll: () => {
                    const groupNumbers = this.state.periodTestData[this.state.period as string]
                      .map(inv => inv.groupId)
                      .filter((grpId, i, self) => self.indexOf(grpId) == i);
                    if(groupNumbers.every(grpId => this.state.collapsedGroups.includes(grpId))){
                      this.setState({ collapsedGroups: [] });
                    }
                    else 
                      this.setState({ collapsedGroups: groupNumbers });
                  }
                }: undefined}
                data={
                 this.state.reportingArticles ? groupedItems : items
                }
                onChange={
                  (newData) => console.log(newData) 
                }
             noItemsText={`${i18n.t('kickbackProject.noItems')}`}
              />
            </div>
           <Stack gap={4}>
            {sumTable}
            </Stack>
          </>
        )}
      </>
    );
  };

 
  private handlePeriodChangeAndActiveStatus = (period: string | null) => {
    this.setState({ period }/*,async() => await this.funcHandleAlreadyReported()*/);
  };
  private checkAndUpdateSelectedContractAndPeriod = (): void => {};

  private handleUpdateForGroupArticles = (updatedValue:ProjectInvoice | undefined,stateAsOfNow:Period) => {
    const headerCelltoUpdateKeys:(keyof Pick<ProjectInvoice,'projectName'|'contractId'|'customerName'|'customerOrgId'|'billingDate'|'tag'|'reference'|'comment'|'projectEndDate'>)[] = 
    ['projectName','contractId','customerName','customerOrgId','billingDate','tag','reference','comment','projectEndDate']
    for (let index = 0; index < stateAsOfNow[this.state.period as string].length; index++) {
      if(stateAsOfNow[this.state.period as string][index].groupId == updatedValue?.groupId){
              for (let j = 0; j < headerCelltoUpdateKeys.length; j++) {
               
                  stateAsOfNow[this.state.period as string][index][headerCelltoUpdateKeys[j]] = updatedValue[headerCelltoUpdateKeys[j]]
                  stateAsOfNow[this.state.period as string][index]['kick'] = updatedValue['kick']
              }
      } 
    }   
  }
  private onChangeForInvoiceNrComment = (val:any,row:Readonly<ProjectInvoice>,key:keyof AssortedFields):void => {
   
    let update: Period = _helpers.deepClone(this.state.periodTestData);
    let itemToChange = this.findRow(update,row.uniqueRowId) as ProjectInvoice
    itemToChange[key] = val
    this.setState({ periodTestData: update});
   
  }
  private onChangeContractUsers = async (
    uniqueRowId: number,
    val: any,
    key: any,
    index?: number
  ) => {
   
    let update: Period = _helpers.deepClone(this.state.periodTestData);
    let itemToChange = this.findRow(update,uniqueRowId)
    let options = _helpers.deepClone(this.state.optionsAvailable);
    if (key == "totalExclMoms") {
      if (typeof itemToChange !== "undefined") {
         const validation = _helpers.validateSum(val);
        itemToChange =   _helpers.updateSum(itemToChange,validation,val)
      
      }
    } 
    else if (key == "projectName") {
      if (this.state.optionsAvailable[key].filter((x) => x == val).length == 0) {
        options[key].push(val[0].label);
        this.setState({ optionsAvailable: options });
      }
      if (typeof itemToChange !== "undefined") {
        itemToChange.projectName = typeof val[0].label == "undefined" ? val[0] : val[0].label;
        if (itemToChange.contractId.length > 0) {
          if ( typeof this.state.projectEndDatesAvailable[itemToChange.contractId][itemToChange.projectName] !== "undefined")
           {
             itemToChange.projectEndDate = this.state.projectEndDatesAvailable[itemToChange.contractId][itemToChange.projectName]
              ?.toString()
              .split("T")[0] as string ?? "";
          } 
          else {
            this.updateDateObject( itemToChange.contractId,itemToChange.projectName,itemToChange.projectEndDate);
          }
        }
      }
    } 
    else if (key == "contractId") {
      if (typeof itemToChange !== "undefined") {
        itemToChange.contractId =
          typeof val[0].label == "undefined" ? val[0] : val[0].label;
          itemToChange.kick = _helpers.convertToPercentage(this.state.contractDict[itemToChange.contractId].kickbackPercent)
        if (itemToChange.projectName.length > 0) {
          if (
            typeof this.state.projectEndDatesAvailable[itemToChange.contractId][
              itemToChange.projectName
            ] !== "undefined"
            ) {
              itemToChange.projectEndDate = this.state.projectEndDatesAvailable[itemToChange.contractId][itemToChange.projectName]
            ?.toString()
            .split("T")[0] as string ?? "";
            update[this.state.period as string].forEach(x => {
              if(x.groupId == itemToChange?.groupId){
                x.kick = itemToChange?.kick
              }
            })
             
            } 
            else {
              this.updateDateObject(
                itemToChange.contractId,
                itemToChange.projectName,
                itemToChange.projectEndDate
                );
              }
            }
          }
    } 
    else if (key == "customerName") {
      
      if (typeof itemToChange !== "undefined") {
        const finder = Object.values(this.state.customerDict).filter((x:{customerName:string,customerOrgId:string,customerId:string}) => x.customerName == val[0])
        itemToChange.customerName = finder[0].customerName
          
          itemToChange.customerOrgId =
          finder[0].customerOrgId;
          itemToChange.customerId = finder[0].customerId;
      }
    } 
    else if (key == "customerOrgId") {
      
      if (typeof itemToChange !== "undefined") {
        itemToChange.customerOrgId =
          typeof val[0].label == "undefined" ? val[0] : val[0].label;
          itemToChange.customerName = this.state.customerDict[itemToChange.customerOrgId].customerName
        itemToChange.customerId = this.state.customerDict[itemToChange.customerOrgId].customerId;
      }
    }
    
    update[this.state.period as string] = _helpers.checkDuplicates(update[this.state.period as string],this.state.reportingArticles,this.state.dbDuplicates,this.state.duplicates['duplicates'])  
    this.handleUpdateForGroupArticles(itemToChange,update)
    this.setState({ periodTestData: update });
  };
  private findRow = (
    values: Period,
    uniqueRowId: number
  ): ProjectInvoice | undefined => {
    return values[this.state.period as string].find(
      (x) => x.uniqueRowId == uniqueRowId
    );
  };

  handleSumOnPeriodReported(rowId: number): void {
    this.checkAndUpdateSelectedContractAndPeriod();
  }
 
  private getInstructionTexts = () => {
    const buff = i18n.t("kickbackProject.kickbackProjectInstructions", {
      returnObjects: true,
    });
    return Object.values(buff);
  };
  private handleToasterMessage = (close: boolean) => {
    this.setState({ showToasterMessage: close });
  };
  private sumTable = (contractdId: string): JSX.Element => {
    const data = this.state.periodTestData[this.state.period as string].filter(
      (x) => x.contractId == contractdId
    );
    const buffArr:ProjectInvoice[] = []
    if(this.state.reportingArticles){
      const grouped =   data.reduce((acc:{[key:string]:ProjectInvoice},item) => {
        if(!acc[`${item.projectName}-${item.reference}-${item.tag}`]){
          
          acc[`${item.projectName}-${item.reference}-${item.tag}`] = item
          acc[`${item.projectName}-${item.reference}-${item.tag}`].totalExclMoms.value = +item.rowSumExclMoms
          return acc
        }
        else{
          acc[`${item.projectName}-${item.reference}-${item.tag}`].totalExclMoms.value += +item.rowSumExclMoms
        return acc
        }
    },{})
    Object.values(grouped).forEach(x => buffArr.push(x))
    buffArr.sort((a,b) =>{ 
      if(a.groupId < b.groupId){
        return -1
      }
      if(a.groupId > b.groupId){
        return 1
      }
      return 0
     })
    }

//console.log(data)
    return data.length > 0 ? (
      <StyledAccordion
        open
        alwaysOpen
        title={` ${i18n.t("kickbackProject.summation")} ${i18n.t(
          "kickbackProject.contract"
        )} ${contractdId}`}
        style={{ 
          borderRadius: '5px',
          borderTop: '5px solid var(--kickback-color)'
        }}
      >
        <div style={{ maxHeight: "20vh" }}>
          <Datalist
            isLoading={this.state.loading}
            uniqueKey="contractId"
            items={
             this.state.reportingArticles ?  buffArr : data
            }
            columns={this.sumTableColumns}
            noItemsText={i18n.t("kickback.table.empty")}
            initialSortKey="contractId"
            setColumns={(cols) => {}}
          />
        </div>
      </StyledAccordion>
    ) : (
      <></>
    );
  };

  private handleStateUpdateAfterSubmitSucces = async (
    period: string,
    status: number,
    message: string,
    data?:any
  ): Promise<void> => {
    if (status == 200) {
      let dataToUpdate: Period = _helpers.deepClone(this.state.periodTestData);
      dataToUpdate[this.state.period as string] = []
      sessionStorage.setItem(`${getActiveSupplier()}-projectReport`,JSON.stringify(dataToUpdate))
      if (Object.keys(dataToUpdate).length == 0) {
        this.renderInfoCard("NOTHINGTOREPORT");
      } else {
        let toasterMessage: any = { Code: 0, Message: message };
        this.setState({
          periodTestData: dataToUpdate,
          period: Object.keys(dataToUpdate)[Object.keys(dataToUpdate).length -1],
          periodsToReport: Object.keys(dataToUpdate),
          openSubmitModal: false,
          openAccordion: false,
          toasterMessage,
          showToasterMessage: true,
        });
      }
    } else if(status == 304){
      let toasterMessage: any = { Code: 304, Message: message };
      sessionStorage.setItem(`${getActiveSupplier()}-projectReport`,JSON.stringify(this.state.periodTestData))
      this.setState({
        toasterMessage,
        showToasterMessage: true,
        openSubmitModal: false,
      });
    }
    else if(status == 208){
      let toasterMessage: any = { Code: 304, Message: message };
      let stateAsOffNow:Period = _helpers.deepClone(this.state.periodTestData)
      const buff:any[] = JSON.parse(data)
      const duplicateDataFound:ProjectInvoice[] = buff.map(x => {
        return {
            uniqueRowId: x.kickBack.id,
            contractId: x.invoice.contractId,
            customerName: x.invoice.customer,
            customerOrgId: x.invoice.customerOrgId,
            customerId:'',
            projectName: x.kickBack.projectName,
            totalExclMoms:{value:+x.invoice.sumExclMoms,display:`${x.invoice.sumExclMoms}`,valid:true,error:false},
            reference: x.kickBack.invoiceReference,
            comment:x.kickBack.comment,
            tag:x.kickBack.invoiceIdentifier,
            projectEndDate:x.kickBack.projectEndDate,
            supplierOrgId: x.kickBack.supplierOrgId,
            kick:_helpers.convertToPercentage(this.state.contractDict[x.invoice.contractId].kickbackPercent),
            billingDate:x.invoice.billingDate,
            isDuplicate:{value:true,backgorundColor:'red'}
        } as ProjectInvoice
      })
      stateAsOffNow[this.state.period as string] = duplicateDataFound
      this.setState({
        toasterMessage,
        showToasterMessage: true,
        openSubmitModal: false,
        periodTestData:stateAsOffNow
      });
    }
  };
  private renderInfoCard = (scenario?: string): void => {
    let card: JSX.Element = <></>;
    switch (scenario) {
      case "ERROR":
        card = (
          <>
            <div className="infoCardWrapper" style={{ height: "15rem" }}>
              <div
                className="infocardContent"
                style={{ backgroundColor: "#b30404" }}
              >
                <div>
                  <FontAwesomeIcon
                    icon="triangle-exclamation"
                    style={{ color: "white", fontSize: 42, padding: 5 }}
                  />
                </div>
              </div>
              <div className="infocardTextError">
                <p>
                  {i18n.t("kickbackProject.error")}

                  <a href="mailto:info@sinfra.se"> info@sinfra.se</a>
                </p>
              </div>
            </div>
          </>
        );
        break;
      case "NODATA":
        card = (
          <>
            <div className="infoCardWrapper">
              <div className="infocardContent">
                <div>
                  <FontAwesomeIcon
                    icon="envelope-circle-check"
                    style={{ color: "white", fontSize: 42, padding: 5 }}
                  />
                </div>
              </div>
              <div className="infocardText">
                <p>{i18n.t("kickbackProject.nothing")}</p>
              </div>
            </div>
          </>
        );
        break;
      case "NOTHINGTOREPORT":
        card = (
          <>
            <div className="infoCardWrapper">
              <div className="infocardContent">
                <div>
                  <FontAwesomeIcon
                    icon="envelope-circle-check"
                    style={{ color: "white", fontSize: 42, padding: 5 }}
                  />
                </div>
              </div>
              <div className="infocardText">
                <p>{i18n.t("kickbackProject.sessionDone")}</p>
              </div>
            </div>
          </>
        );
        break;
      default:
        break;
    }
    this.setState({ infoCard: card, nothingToReport: true }, () =>
      this.setState({ loading: false })
    );
  };
  private handleSave = (): void => {
    sessionStorage.setItem(`${getActiveSupplier()}-projectReport`,JSON.stringify(this.state.periodTestData))
  }
 private findDbDuplicates  = (data:ProjectInvoice[]):void => {
 
  const buff = structuredClone(this.state.periodTestData[this.state.period as string])
  const updatedBuff = buff.map((x:ProjectInvoice) => {
    if (data.some((q:ProjectInvoice) => q.uniqueRowId === x.uniqueRowId)) {
      return { ...x, isDuplicate: {value:true,backgorundColor:generateRgb()} };
    }
    return x;
  });
 // this.setState(prevState => ({...prevState,duplicates:{...prevState.duplicates,'duplicates':true}}))
  this.setState({dbDuplicates:data,periodTestData:{...this.state.periodTestData,[this.state.period as string]:updatedBuff}},() => 
    this.setState(prevState => ({...prevState,duplicates:{...prevState.duplicates,'duplicates':true}}))
  )
 
 }
  private handleDuplicatesCloseModal = (): void => {
    this.setState(prevState => ({duplicatesCheckModal:!prevState.duplicatesCheckModal}))
  }
  
  render() {
 
    return (
      <>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.nothingToReport ? (
          <>{this.state.infoCard}</>
        ) : (
          <>
            {this.state.showToasterMessage && (
              <ToasterMsg
                closeDelayMs={2000}
                autoClose={true}
                handleToastClose={this.handleToasterMessage}
                show={this.state.showToasterMessage}
                message={this.state.toasterMessage}
              />
            )}
            <Stack gap={4} className="project-report-wrapper">
             <ReportNotice/>
              <div>
                <h2>{i18n.t('kickbackProject.title')}</h2>
                {
                  i18n.t('kickbackProject.description').toString().length > 0 &&
                  <Trans i18nKey={'kickbackProject.description'}/>
                }
              </div>
              <NullReport />
              <div style={{
                borderRadius: '5px',
                borderTop: '5px solid var(--statistic-color)',
                boxShadow: '0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 0 1px rgba(9, 30, 66, 0.08)',
                padding: '10px'
              }}>
                <div>
                  <h3>{i18n.t('kickbackProject.invoiceReportTitle')}</h3>
                  {this.state.instructionTexts.length > 0 && (
                    <ul>
                      {this.state.instructionTexts.map((text) => (
                        <li><Trans>{text}</Trans></li>
                      ))}
                    </ul>
                  )}
                </div>
             
                <div style={{ position: "relative" }}>
                  <Stack  direction="horizontal" gap={3} style={{ flexWrap: "wrap", justifyContent:"space-between",
                      margin: "10px 0px",}}>
                      <Stack direction="horizontal" gap={3}>
                        <StyledCheckbox isChecked={this.state.duplicates['warnings']/*this.state.filterWarnings*/} onChange={(newVal) => this.setState(prevState => ({...prevState,duplicates:{...prevState.duplicates,'warnings':newVal}})/*,() => this.filter(newVal)*/)} label={i18n.t('invoiceReport.showWarnings')}/>
                        <StyledCheckbox isChecked={this.state.duplicates['errors']/*this.state.filterErrors*/} onChange={(newVal) => this.setState(prevState => ({...prevState,duplicates:{...prevState.duplicates,'errors':newVal}})/*,() => this.filter(newVal)*/)} label={i18n.t('invoiceReport.showErrors')}/>
                       
                        <StyledCheckbox isChecked={this.state.duplicates['duplicates']} onChange={(newVal) => this.setState(prevState => ({...prevState,duplicates:{...prevState.duplicates,'duplicates':newVal}})/*,() => this.filter(newVal)*/) } label={i18n.t('kickbackProject.showDuplicates')}/> 
                        {typeof this.state.departmentReported !== 'undefined' &&
                          <DepartmentReportedView period={this.state.period as string} departmentReported={this.state.departmentReported}/>
                        }
                      </Stack>
                      <Stack direction="horizontal" gap={3}> 
                        <Form.Group>
                        <Form.Select
                          
                          style={{ textTransform: "capitalize", height: "41.33px" }}
                          value={this.state.period as string}
                          onChange={(ev) => {
                            this.handlePeriodChangeAndActiveStatus(ev.target.value);
                          }}
                        >
         
                          {this.state.periodsToReport.map((x) => {
                            return <option>{x}</option>;
                          })}
                        </Form.Select>
             
                        
                      </Form.Group>
                      <Button onClick={() => { this.setState({ templateListModal: true }) }}>
                  <FontAwesomeIcon icon={faFolder} style={{marginRight: 8}}/>{i18n.t('general.templates')}
                  </Button>
                  {this.state.showFileImportModal === false &&
                  <FileUploadInput
                    onChange={ (newValues) => {
                      
                      let dataToValidate = newValues as ProjectInvoice[]
                      const valid =  ProjectReportValidation({validContracts:this.state.contractDict,validCustomers:this.state.customerDict,availableOptions:this.state.optionsAvailable,existingProjects:this.state.projectEndDatesAvailable},dataToValidate,this.state.reportingArticles)
                      _helpers.finalizeProjectEndDates(valid[0],valid[1].existingProjects)
                      let stateAsOffNow:Period = _helpers.deepClone(this.state.periodTestData)
                      stateAsOffNow[this.state.period as string]=stateAsOffNow[this.state.period as string].concat(valid[0]) //= valid[0]
                      if(stateAsOffNow[this.state.period as string].length > 0){
                        stateAsOffNow[this.state.period as string]=_helpers.checkDuplicates(stateAsOffNow[this.state.period as string],this.state.reportingArticles,this.state.dbDuplicates,this.state.duplicates['duplicates'])
                        stateAsOffNow[this.state.period as string] = _helpers.articleFileUploadedNowGroupAndAddIds(stateAsOffNow[this.state.period as string])
                        /*quickfix where inaccurate dublicates have been found added for now until csrd is the standard for reporting*/
                        if(this.state.reportingArticles){
                          stateAsOffNow[this.state.period as string] =  _helpers.finalizeCsrdDups(stateAsOffNow[this.state.period as string])
                          
                         //console.log(checkedTempDuplicates)
                        }
                      }
                      this.setState({periodTestData:stateAsOffNow,projectEndDatesAvailable:valid[1].existingProjects,optionsAvailable:valid[1].availableOptions})
                    }}
                    invoiceFormat={this.state.reportingArticles ? "ProjectArticle" :"Project"}
                  />}
                    
                      <Button
                        className="btn button-submit"
                        onClick={this.handleDuplicatesCloseModal/*this.handleSubmitModal*/}
                        disabled={
                        this.state.periodTestData[this.state.period as string].length == 0 ||
                        (typeof this.state.departmentReported !== 'undefined' && this.state.departmentReported[this.state.period as string]?.cantReport )
                      }
                      >
                        <>
                          <FontAwesomeIcon
                            icon={faPaperPlane}
                            style={{ marginRight: 8 }}
                          />
                          {i18n.t("general.review")} + {i18n.t("general.send")}
                        </>
                      </Button>
                      <Button className="btn button-danger" 
                          onClick={() => {this.setState({ showClearModal: true })}}
                        >
                          <><FontAwesomeIcon icon={faTrash} style={{marginRight: 8}}/>{i18n.t('general.clear')}</>
                        </Button>
                        <OverlayTrigger
                        placement='bottom'
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => this.showValidationInfo(props, 'save')}
                        >
                        <Button
                        onClick={this.handleSave}
                      
                        disabled={
                          false //this.state.selectedContractKey?.length == 0 ? true : false
                        }
                      >
                        <>
                          <FontAwesomeIcon
                            icon='save'
                            style={{ marginRight: 8 }}
                          />
                          {i18n.t('kickbackProject.saveButton')}
                        </>
                      </Button>
                      </OverlayTrigger>
                    </Stack>
                  </Stack>
              
                </div>
              
              <div
              >
                {this.state.loading == false && <>{this.createTabTest()}</>}

              </div>
              </div>
            </Stack>
            {this.state.duplicatesCheckModal && (
             
             <KickbackReportDuplicatesModal
             closeModal={this.handleDuplicatesCloseModal}
             period={this.state.period as string}
             report={this.state.periodTestData[this.state.period as string]}
             invalidColors={this.invalidColors}
             handleSubmitModal={this.handleDuplicateVerified}
             customerDict={this.state.customerDict}
             contractDict={this.state.contractDict}
             //deepClone={this.deepClone}
             validateSum={_helpers.validateSum}
             csrd={this.state.reportingArticles}
             editableColumns={this.state.editableColumns}
             groupedColumns={this.groupedColumns}
             findDbDuplicates={this.findDbDuplicates} 
             />
             )}
            {this.state.openSubmitModal && (
              <SubmitKickbackProject
                submitHandler={this.handleStateUpdateAfterSubmitSucces}
                data={this.state.periodTestData}
                period={this.state.period as string}
                closeModal={this.handleSubmitModal}
                onClickDataCard={(key: "warnings"|"duplicates"|"errors") => {
                  const falsifyOtherKeys = ["warnings","duplicates","errors"]
                    .filter(k => k != key)
                    .reduce((prev, curr) => {
                      return { ...prev, [curr]: false }
                    }, {} as {[key:string]:boolean});
                  this.setState(prevState => 
                    ({...prevState, openSubmitModal: false,duplicates:{...prevState.duplicates,[key]:true, ...falsifyOtherKeys}}));
                }}
                contractDict={this.state.contractDict}
                articleReporter={this.state.reportingArticles}
                rowEdit={this.state.rowEdit}  
              />
            )}
             {
            this.state.showClearModal &&
            <StyledModal 
              onDismiss={() => { this.setState({ showClearModal: false }) }}
              title={i18n.t('invoiceReport.clearModalTitle').toString()}
              body={
                <div>
                  {i18n.t('invoiceReport.clearModalContent')}
                </div>
              }
              footer={
                <Stack gap={3} direction="horizontal" style={{justifyContent:"center"}}>
                  <Button style={{width: 100}} className="button button-danger" 
                    onClick={() => {
                      let stateAsOffNow = _helpers.deepClone(this.state.periodTestData)
                      stateAsOffNow[this.state.period as string] = [] 
                      
                      this.setState({ periodTestData:stateAsOffNow,showClearModal: false});
                      
                    }}>
                    {i18n.t('general.confirm')}
                  </Button>
                  <Button style={{width: 100}} className="button button-cancel" 
                    onClick={() => { 
                      this.setState({ showClearModal: false }); 
                    }}>
                      {i18n.t('general.cancel')}
                  </Button>
                </Stack>
              }
            />
          }
           {
            this.state.templateListModal &&
            <TemplateListModal 
              onDismiss={() => { this.setState({ templateListModal: false }) }}
            />
          }
        
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(KickbackProjectReportPage);
