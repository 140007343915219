import { MutableRefObject, Ref, useEffect, useRef, useState } from "react";
import { DataService } from "../services/DataService";
import Datalist, { ListColumn } from "../components/Datalist/Datalist";
import { TUser } from "../services/API/Users";
import { Alert, Button, Col, Overlay, OverlayTrigger, Popover, Row, Spinner, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Code, IsUserSinfra } from "../lib/utils";
import { InfoModal } from "../components/modal/InfoModal";
import { TSupplier } from "../services/API/Suppliers";
import { SupplierUserModal } from "../components/modal/SupplierUserModal";
import StyledCheckbox from "../components/StyledCheckbox/StyledCheckbox";
import { Contract } from "../services/API/Contracts";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { StyledModal } from "../components/modal/StyledModal";
import DepartmentList, { useDepartments } from "../components/Supplier/DepartmentList/DepartmentList";
import MemberList, { useMembers } from "../components/Supplier/MemberList/MemberList";
import { Department, DepartmentFunctionArgs } from "../services/API/Departments";

interface Props {}

function EditSupplierPage(props: Props) {
  const { orgNr } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [supplier, setSupplier] = useState<TSupplier>();
  const [ contracts, setContracts ] = useState<Contract[]>([]);
  const [errorMsg, setErrorMessage] = useState("");
  const [successText, setSuccessText] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const departments = useDepartments(orgNr);
  const members = useMembers(orgNr as string);
  const { t } = useTranslation();


  useEffect(() => {
    if (!IsUserSinfra()) window.location.href = "/";
    if (typeof orgNr != "string") {
      console.error("id is not valid: " + orgNr);
      window.location.href = "/";
      return;
    }
    Promise.all([initSupplier(orgNr), initContracts(orgNr)])
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(supplier)
  const initContracts = async (supplierOrgNr : string) => {
    const fetchedContracts = await DataService.Contracts.GetBySupplierOrgNr(supplierOrgNr);
    setContracts(fetchedContracts);
  }

  const initSupplier = async (supplierOrgNr: string) => {
    const supplier = await DataService.Suppliers.GetByOrgNr(supplierOrgNr);
    setSupplier(supplier);
  };

  const onUpdateSupplier = async () => {
    if(supplier?.divided_reporting){
      const isAllAssigned = contracts.every(contract => members.items.find(usr => usr.attributes.reporting_contracts.indexOf(contract.contractId) > -1));
      if(!isAllAssigned){
        setErrorMessage(t("editSupplier.contractList.allMustBeAssigned").toString());
        return;
      }
    }
    setIsSaving(true);
    setErrorMessage("");
    const res = await DataService.Suppliers.UpdateSupplierGroup(supplier as TSupplier, members.items,departments.items);
    if(res?.Code != Code.OK){
      setErrorMessage(res?.Message ?? "Error, something went wrong when updating supplier");
      setIsSaving(false);
    }
    else{
      setSuccessText(res?.Message ?? "Success, supplier had been updated");
    }
  }

  const renderField = (label: string, value: string | undefined) => {
    return (
      <>
        <div className="supplier-page-field-label">{label}</div>
        <div style={{ marginBottom: 0, height: "unset" }} className="supplier-page-field-value">{value}</div>
      </>
    );
  };
 
  return (
    
    <Stack gap={4} className="supplier-page">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div>
          <div className="supplier-page-title">{t("editSupplier.title")}</div>
          <Stack style={{ marginTop: 8 }} gap={4}>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderField(t("general.supplierName"), supplier?.supplierName)}
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderField(
                  t("general.supplierOrgId"),
                  supplier?.supplierOrgNr
                )}
              </Col>
              <Col xs={6} sm={3} md={3} lg={3}>
              <div className='field-label'>{t("editSupplier.preventInvoiceReport")}</div>
                <div className='field-value'>
                  <StyledCheckbox 
                    disabled={isSaving} 
                    isChecked={supplier?.prevent_invoice_report || false}
                    label={supplier?.prevent_invoice_report ? t('general.yes') : t('general.no')} 
                    onChange={(newVal) => { 
                      if(supplier)
                        setSupplier({...supplier, prevent_invoice_report: newVal});
                     }}
                  />
                </div>
              </Col>
              <Col xs={6} sm={3} md={3} lg={3}>
              <div className='field-label'>{t("editSupplier.preventKickbackReport")}</div>
                <div className='field-value'>
                  <StyledCheckbox 
                    disabled={isSaving} 
                    isChecked={supplier?.prevent_kickback_report || false}
                    label={supplier?.prevent_kickback_report ? t('general.yes') : t('general.no')} 
                    onChange={(newVal) => { 
                      if(supplier)
                        setSupplier({...supplier, prevent_kickback_report: newVal});
                     }}
                  />
                </div>
              </Col>
              <Col xs={6} sm={3} md={3} lg={3}>
              <div className='field-label'>{t("editSupplier.activateProjectReporting")}</div>
                <div className='field-value'>
                  <StyledCheckbox 
                    disabled={isSaving} 
                    isChecked={supplier?.project_report || false}
                    label={supplier?.project_report ? t('general.yes') : t('general.no')} 
                    onChange={(newVal) => { 
                      if(supplier)
                        setSupplier({...supplier, project_report: newVal});
                     }}
                  />
                </div>
              </Col>
              <Col xs={6} sm={3} md={3} lg={3}>
              <div className='field-label'>{t("editSupplier.EnableArticleReporting")}</div>
                <div className='field-value'>
                  <StyledCheckbox 
                    disabled={isSaving} 
                    isChecked={supplier?.testing_report || false}
                    label={supplier?.testing_report ? t('general.yes') : t('general.no')} 
                    onChange={(newVal) => { 
                      if(supplier)
                        setSupplier({...supplier, testing_report: newVal});
                     }}
                  />
                </div>
              </Col>
              <Col xs={6} sm={3} md={3} lg={3}>
              <div className='field-label'>Aktivera rad editering</div>
                <div className='field-value'>
                  <StyledCheckbox 
                    disabled={isSaving} 
                    isChecked={supplier?.rowEdit || false}
                    label={supplier?.rowEdit ? t('general.yes') : t('general.no')} 
                    onChange={(newVal) => { 
                      if(supplier)
                        setSupplier({...supplier, rowEdit: newVal});
                     }}
                  />
                </div>
              </Col>
              
            </Row>
           
            <Row style={{ gap: 10 }}>
              <MemberList 
                members={members.items}
                onChange={members.AddOrUpdate}
                onDelete={members.RemoveById}
                departments={departments.items}
              />
            </Row>
            <Row style={{gap:10}}>
              <DepartmentList
                departments={departments.items}
                onChange={departments.AddOrUpdate}
                onDelete={departments.RemoveById}
              />
            </Row>
            <Row style={{gap:10}}>
              <StyledCheckbox
                isChecked={supplier?.divided_reporting || false}
                label={t("editSupplier.reportMultipleKickbacks")}
                onChange={(newVal) => { 
                  if(supplier)
                    setSupplier({...supplier, divided_reporting: newVal});
                 }}
              />
              
              {
                (supplier?.divided_reporting || false) &&
                <ContractList contracts={contracts} users={members.items} setUsers={(users) => {users.forEach(members.AddOrUpdate)}}/>
              }
             
            </Row>
            {
              errorMsg.length > 0 &&
              <Row>
                <div className='error-message'>
                  <span>{t('general.errorMsg')}:</span>
                  {errorMsg}
                </div>
              </Row>
            }
            <Row>
            <div className="supplier-page-tools">
                {/* <Button
                  disabled={ isSaving }
                  onClick={() => {
                    window.location.href = `/newUser/${supplier?.supplierOrgNr}`;
                  }}
                >
                  <FontAwesomeIcon
                    icon={faAdd}
                    style={{ marginRight: "4px" }}
                  />
                  {t("editSupplier.addUser")}
                </Button> */}
                <Button className="button-submit" 
                  disabled={ isSaving }
                  onClick={() => { onUpdateSupplier() }}>
                    {
                      isSaving ?
                      <Spinner size="sm"/>
                      :t('general.update')
                    }
                </Button>
                <Button
                  className="button-cancel"
                  disabled={ isSaving }
                  onClick={() => window.history.go(-1)}
                >
                  {t("general.back")}
                </Button>
              </div>
              </Row>
          </Stack>
        </div>
      )}
      {successText && (
        <InfoModal
          bodyText={successText}
          title={t("editSupplier.infoModalSuccess")}
          onDismiss={() => window.location.href = "/supplierManagement"}
        />
      )}
    </Stack>
  );
}

export default EditSupplierPage;

function ContractList({ contracts, users, setUsers }: { contracts: Contract[]; users: TUser[]; setUsers:(users:TUser[]) => void }) {
  const { t } = useTranslation();
  const usersRef = useRef(users);
  useEffect(() => {
    usersRef.current = users;
    setColumns([...columns]);
  }, [users]);
 
  const [columns, setColumns] = useState<ListColumn<Contract>[]>([
    {
      fieldName: "contractId",
      isActive: true,
      key: "contractId",
      minWidth: 100,
      maxWidth: 100,
      name: t('editSupplier.contractList.contractId'),
    },
    {
      fieldName: "users",
      isActive: true,
      key: "users",
      minWidth: 100,
      name: t('editSupplier.contractList.users'),
      onRender: (item, col, i) => {
        return <Typeahead
        placeholder={t('editSupplier.contractList.placeHolder').toString()}
        id={`users_${item.id}`}
        options={usersRef.current.map((user) => ({ title: user.email, key: user.id }))}
        selected={usersRef.current
          .filter(
            (user) =>
              user.attributes?.reporting_contracts?.indexOf(item.contractId) > -1 ??
              false
          )
          .map((user) => ({ title: user.email, key: user.id }))}
        onChange={(selected) => {
          //@ts-ignore
          const selectedUsers = usersRef.current.filter(user => selected.find((sel) => sel.key == user.id));
          const updatedUsers = usersRef.current.map(user => {
            const contractIds = user.attributes.reporting_contracts.filter(contId => contId != item.contractId);
            user.attributes.reporting_contracts = selectedUsers.find(u => u.id == user.id) ?
              contractIds.concat(item.contractId)
              :
              contractIds;
            return user;
          })
          setUsers(updatedUsers);
        }}
        labelKey={"title"}
        multiple
        positionFixed
      />
      },
    }
  ]);

  return (
    <>
    <div className="supplier-page-field-label">
      {t("editSupplier.contractList.title")}
    </div>
    <Datalist
      items={contracts}
      columns={columns}
      setColumns={setColumns}
      noItemsText={t("editSupplier.contractList.emptyListText")}
      uniqueKey={"contractId"}
    />
    </>
  );
}
