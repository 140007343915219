/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable eqeqeq */
import * as React from 'react'
import { faTrash, faPlus,
faClone,
faWarning,
faCircleCheck,
faExclamation} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack
} from "react-bootstrap";
import EditableTable, { GroupedCol } from "../EditableTable";
import { ActionModalState, HandleActionModal, InternalInvoiceEdit, InternalInvoiceReport, InternalInvoiceWithCsrdAndGroupId, InternalKeysForEIRT, InvoiceKey, /*InvoicesWithCsrdAndGroupId,*/ TableCellOnChangeCallBack } from './editTypes';
import i18n from "../../i18n";
import {CellContext, ColumnDef, HeaderContext 
} from "@tanstack/react-table";
import { ReadFriendlyNumber, StringToNumber } from '../../lib/utils';
import { Contract } from '../../services/API/Contracts';
import { Customer } from '../../services/API/Customer';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { ChoiceInput } from '../fields/ChoiceInput';
import { editUtils } from './editUtils';

type InternalMap = {
  [key:number]:{[key:string]:any}
}

interface IEditInvoiceTableProps{
    reportType:InternalKeysForEIRT;
    data:InternalInvoiceReport/*InvoiceReport*/;
    callBack:TableCellOnChangeCallBack;
    invoiceKey:keyof InvoiceKey;
    addRow:(groupId?:number)=>void;
    deleteRow:(id:number)=>void;
    copyRow:<T extends object>(item:T,index:number) => void;
    deleteModal:ActionModalState/*DeleteModalState*/;
    handleDeleteModal:HandleActionModal/*HandleDeleteModal*/;
    contract:Contract[];
    customers:Customer[];
}
interface IEditInvoiceTableState{
  collapsedGroups:number[];
  cache:InternalMap;
}
class EditInvoiceReportTable extends React.Component<IEditInvoiceTableProps,IEditInvoiceTableState>{
  constructor(props:IEditInvoiceTableProps){
    super(props)
    this._cell = this._cell.bind(this)
    this.handleCollapsingOfGroups = this.handleCollapsingOfGroups.bind(this)
    this.setCache = this.setCache.bind(this)
    this.state = {
      collapsedGroups:[],
      cache:{},
    }
  }
 
  timerHandler: NodeJS.Timeout | null = null;
 
  csrdCols:ColumnDef<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/>[] = [
    {
      id: "customer",
      header: () => i18n.t('invoiceReport.table.headers.customer'),
      cell: (props:CellContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => this._cell(props.row.original, "customerName",false,props.row.index),
      minSize: 100,
      footer: (info:HeaderContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => {
        // return <>{i18n.t('invoiceReport.table.footers.noOfRows')} {this.state.invoices.length}</>
      },
    },
    {
      id: "customerOrgId",
      header: () => i18n.t('invoiceReport.table.headers.customerOrgId'),
      minSize: 100,
      cell: (props:CellContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => this._cell(props.row.original, "customerOrgId",false,props.row.index)
    },
    {
      id: 'contractId',
      header: () => i18n.t('invoiceReport.table.headers.contractId'),
      minSize: 80,
      maxSize: 80,
      cell: (props:CellContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => this._cell(props.row.original, "contractId",false,props.row.index)
    },
    {
      id: 'invoiceNumber',
      header: () => i18n.t('invoiceReport.table.headers.invoiceNumber'),
      minSize: 100,
      maxSize: 100,
      
      cell: (props:CellContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => this._cell(props.row.original, "invoiceNumber",false,props.row.index)
    },
    {
      accessorKey: 'billingDate.formattedValue',
      header: () => i18n.t('invoiceReport.table.headers.date'),
      minSize: 150,
      maxSize: 150,
      cell: (props:CellContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => this._cell(props.row.original, "billingDate",false,props.row.index)
    },
    {
      id: "sumExclMoms",
      header: () => i18n.t('invoiceReport.table.headers.sum'),
      minSize: 80,
      cell: (props:CellContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => {
        if(this.props.data && this.props.invoiceKey){
          const buff = this.props.data.csrdGroup 
          return <div className="disabled-cell">
        {
          ReadFriendlyNumber(
            buff
            ?.filter((inv:any) => inv?.groupId == props.row.original?.groupId)
            .reduce((prev:any, curr:any) => {
              return prev + StringToNumber(curr?.rowSumExclMoms.toString())
            }, 0)
          )
        }
      </div>
        }else{
          return this._cell(props.row.original, "rowSumExclMoms",false,props.row.index) 
        }
      },
      footer: (info:HeaderContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => {
        if(this.props.data && this.props.invoiceKey){
          const buff = this.props.data.csrdGroup          
          const sum = buff?.reduce((prev:any, curr:any, i:any, self:any) => {
            if(typeof curr?.rowSumExclMoms == 'undefined'){
            
              return prev
            }else{
              const value = curr?.rowSumExclMoms?.toString().replaceAll(" ", "").replaceAll(',',".");
            return prev + parseFloat(value)
            }
            
          }, 0.0);
          return <>{i18n.t('invoiceReport.table.footers.totalSum')} {ReadFriendlyNumber(sum)}</>
        }       
      },
    },
    {
      id:"noOfArticles",
      header: () => i18n.t('invoiceReport.table.headers.noOfArticles'),
      minSize:40,
      maxSize:40,
      cell: (props:CellContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => {
        if(this.props.data && this.props.invoiceKey){
          const buff = this.props.data.csrdGroup 
          const groupItems = buff?.filter((inv:any) => inv?.groupId == props.row.original?.groupId);
        return <div className="disabled-cell">
          {
               groupItems?.length
          }
        </div>
        }
        
      }
    },
    {
      id:"warnings/errors",
      header: () => "",
      minSize:25,
      maxSize:25,
      cell: (props:CellContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => {
        if(this.props.data && this.props.invoiceKey){
          const buff = this.props.data.csrdGroup 
          const groupItems = buff?.filter((inv:any) => inv?.groupId == props.row.original?.groupId) as /*InvoicesWithCsrdAndGroupId*/InternalInvoiceWithCsrdAndGroupId[];
          
        let {errors,warnings} = {errors:0,warnings:0}
        const result = Object.keys(groupItems[0]).filter(x => x !== 'cpvCode' && x !== 'cpvName' &&x !=='emissionFactor' &&x !== 'resultStatus'&&x !=='totalEmissionsKg') ;
        groupItems.forEach(x => result.forEach((key:string) => {
          const value = x[key as keyof InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/];
          if (value === null || value === undefined  || value === '' || (key === 'billingDate' && value.toString() === 'Invalid Date') ) {

            errors += 1;
          }
        }));
        let icon = errors || warnings ? faWarning : faCircleCheck;
        let color = "#07a235";
        if(errors > 0) color = "#b30404";
        else if(warnings > 0) color = "#f8b133";
        return <>{
          <Stack direction="horizontal" gap={2} style={{height: "100%",justifyContent: "center"}}>
              <FontAwesomeIcon icon={icon} color={color} style={{height: "60%", marginRight: 6, marginLeft: 6}}/>
          </Stack>
        }</>
        }
      }
    },
     {
      accessorKey: "none",
      header: () => i18n.t('invoiceReport.table.headers.tools'),
      maxSize: 50,
      minSize: 50,
      cell: (props:CellContext<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/,unknown>) => {
        return <Stack direction="horizontal" style={{height:42, justifyContent:"space-evenly"}} >
        <Button 
            className="outline-button row-btn" 
            onClick={(ev) => {
              this.props.handleDeleteModal('COPY',true,-1,'id',props.row.original,props.row.index)
            }}>
          <FontAwesomeIcon icon={faClone} />
        </Button>
        <Button 
          className="button outline-button-danger row-btn" 
          onClick={(ev) => {
           this.props.handleDeleteModal('DELETEROW',true,props.row.original.groupId,'groupId')
          }}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Stack>
      },
      footer: () => {
        
        return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} onClick={() => this.props.handleDeleteModal('ADDROW',true,-1) /*this.props.addRow()*/}>
          <FontAwesomeIcon icon={faPlus}/>
          {i18n.t('invoiceReport.table.footers.add')}
        </Button>
      },
      meta: "skipPaddingOnFooter"
    }
  ]
   groupedColumns: GroupedCol<InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/>[] = [
    {
      id: "articleNumber",
      header: i18n.t("invoiceReport.table.headers.articleNr"),
      cell: (props) => this._cell(props, "articleNumber",false,props.id),
      minSize: "100px"
    },
    {
      id: "articleText",
      header: i18n.t("invoiceReport.table.headers.articleText"),
      cell: (props) => this._cell(props, "articleText",false,props.id),
      minSize: "100px"
    },
    {
      id: "articleQuantity",
      header: i18n.t("invoiceReport.table.headers.articleQuantity"),
      cell: (props) => this._cell(props, "articleQuantity",false,props.id),
      minSize: "50px"
    },
    {
      id: "articleQuantityType",
      header: i18n.t("invoiceReport.table.headers.articleQuantityType"),
      cell: (props) => this._cell(props, "articleQuantityType",false,props.id),
      minSize: "30px"
    },
    {
      id: "articlePricePerUnit",
      header: i18n.t("invoiceReport.table.headers.articlePricePerUnit"),
      cell: (props) =>this._cell(props, "articlePricePerUnit",false,props.id),
      minSize: "100px"
    },
    {
      id: "rowSumExclMoms",
      header: i18n.t("invoiceReport.table.headers.rowSumExclMoms"),
      minSize: "100px",
      cell: (props) => this._cell(props, "rowSumExclMoms",false,props.id),
    },
    {
      id: "nestedTools",
      minSize:"5%",
     header: "",
     cell: (row, index) => {
       return <Button 
         className="button outline-button-danger row-btn" 
         style={{ width: "100%" }}
         onClick={(ev) => {   
            this.props.handleDeleteModal('DELETEARTICLEROW',true,row.id,'id')
         }}>
         <FontAwesomeIcon icon={faTrash} />
       </Button>
     },
     footer: (col, groupId) => {
      return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} 
        onClick={(e) => {
        this.props.handleDeleteModal('ADDARTICLE',true,-1,'groupId',undefined,groupId)
        }}>
        <FontAwesomeIcon icon={faPlus}/>
        {i18n.t("invoiceReport.newArticleText")}
      </Button>
    },
     meta: "skipPaddingOnFooter"
   }
  ];
  invoiceCOls:ColumnDef<InternalInvoiceEdit/*Invoice*/>[] = [
    {
      id: "customer",
      header: () => i18n.t('invoiceReport.table.headers.customer'),
      cell: (props:CellContext<InternalInvoiceEdit/*Invoice*/,unknown>) => this._cell(props.row.original, "customerName",false,props.row.index),
      minSize: 100,
      footer: (info:HeaderContext<InternalInvoiceEdit/*Invoice*/,unknown>) => {
        // return <>{i18n.t('invoiceReport.table.footers.noOfRows')} {this.state.invoices.length}</>
      },
    },
    {
      id: "customerOrgId",
      header: () => i18n.t('invoiceReport.table.headers.customerOrgId'),
      minSize: 100,
      cell: (props:CellContext<InternalInvoiceEdit/*Invoice*/,unknown>) => this._cell(props.row.original, "customerOrgId",false,props.row.index)
    },
    {
      id: 'contractId',
      header: () => i18n.t('invoiceReport.table.headers.contractId'),
      minSize: 80,
      maxSize: 80,
      cell: (props:CellContext<InternalInvoiceEdit/*Invoice*/,unknown>) => this._cell(props.row.original, "contractId",false,props.row.index)
    },
    {
      id: 'invoiceNumber',
      header: () => i18n.t('invoiceReport.table.headers.invoiceNumber'),
      minSize: 100,
      maxSize: 100,
      
      cell: (props:CellContext<InternalInvoiceEdit/*Invoice*/,unknown>) => this._cell(props.row.original, "invoiceNumber",false,props.row.index)
    },
    {
      accessorKey: 'billingDate.formattedValue',
      header: () => i18n.t('invoiceReport.table.headers.date'),
      minSize: 100,
      maxSize: 100,
      cell: (props:CellContext<InternalInvoiceEdit/*Invoice*/,unknown>) => this._cell(props.row.original, "billingDate",false,props.row.index)
    },
    {
      id: "priceExcMoms",
      header: () => i18n.t('invoiceReport.table.headers.sum'),
      minSize: 80,
      cell: (props:CellContext<InternalInvoiceEdit/*Invoice*/,unknown>) => {
        return this._cell(props.row.original, "priceExcMoms",false,props.row.index)
      },
      footer: () => {
        if(this.props.data && this.props.invoiceKey){
          const buff:any[] | undefined = this.props.data[this.props.invoiceKey] 
          const sum = buff?.reduce((prev:number, curr:InternalInvoiceEdit/*Invoice*/, i, self) => {
          const value = curr.priceExcMoms.toString().replaceAll(" ", "").replaceAll(',',"."); 
          return prev + parseFloat(value)
        }, 0.0);
        return <>{i18n.t('invoiceReport.table.footers.totalSum')} {ReadFriendlyNumber(sum)}</>
        }
      },
    },
    {
      id:"noOfArticles",
      header: () => i18n.t('invoiceReport.table.headers.noOfArticles'),
      minSize:40,
      maxSize:40,
      cell: (props:CellContext<InternalInvoiceEdit/*Invoice*/,unknown>) => {
        const groupItems = 1
        return <div className="fieldWrapper">
          {
            groupItems
          }
        </div>
      }
    },
    {
      id:"warnings/errors",
      header: () => "",
      minSize:25,
      maxSize:25,
      cell: (props:CellContext<InternalInvoiceEdit/*Invoice*/,unknown>) => {      
        let {errors,warnings} = {errors:0,warnings:0}
        const result = Object.keys(props.row.original).filter(x => x !== 'csrdConnectionId' && x !== 'status' && x !== 'id' && x !== 'customerId' &&x !=='priceIncMoms' &&x !== 'isRemoved'&&x !==' noOfInvoices' && x !== 'type' && x!== 'filePath' && x!=='projectName' && x!== 'projectEndDate' && x!=='comment' && x !== 'invoiceReference' && x !== 'invoiceIdentifier');
        result.forEach((key:string) => {
          const value = props.row.original[key as keyof InternalInvoiceEdit];
          if (value === null || value === undefined  || value === '' || (key === 'billingDate' && value.toString() === 'Invalid Date') ) {
            errors += 1;
          }
        })
        let icon = errors || warnings ? faWarning : faCircleCheck;
        let color = "#07a235";
        if(errors > 0) color = "#b30404";
        else if(warnings > 0) color = "#f8b133";
        return <>{
          <Stack direction="horizontal" gap={2} style={{/*height: "100%",*/justifyContent: "center"}}>
              <FontAwesomeIcon icon={icon} color={color} style={{height: "20px", marginRight: 6, marginLeft: 6}}/>
          </Stack>
        }</>      
      }
    },
     {
      accessorKey: "none",
      header: () => i18n.t('invoiceReport.table.headers.tools'),
      maxSize: 50,
      minSize: 50,
      cell: (props:CellContext<InternalInvoiceEdit/*Invoice*/,unknown>) => {
        return <Stack direction="horizontal" style={{height:42, justifyContent:"space-evenly"}} >
        <Button 
            className="outline-button row-btn" 
            onClick={(ev) => {
              this.props.handleDeleteModal('COPY',true,-1,'id',props.row.original,props.row.index)
             //this.props.copyRow(,props.row.index)
            }}>
          <FontAwesomeIcon icon={faClone} />
        </Button>
        <Button 
          className="button outline-button-danger row-btn" 
          onClick={(ev) => {              
            this.props.handleDeleteModal('DELETEROW',true,props.row.original.id)
          }}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Stack>
      },
      footer: () => {
      
        return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} onClick={()=>this.props.handleDeleteModal('ADDROW',true,-1)/*this.props.addRow()*/}>
          <FontAwesomeIcon icon={faPlus}/>
          {i18n.t('invoiceReport.table.footers.add')}
        </Button>
      },
      meta: "skipPaddingOnFooter"
    }
  ]

  componentWillUnmount(): void {
    if (this.timerHandler) {
      clearTimeout(this.timerHandler);
    }
  }
 handleTimerAndCache = () => {

 }
  handleCollapsingOfGroups(groupId:number){
    this.setState({collapsedGroups:this.state.collapsedGroups.filter(g => g != groupId)})
  }
  checkKeyForTypeAheds = (key:string):boolean => {
    if(key == 'contractId' || key == 'customerOrgId' || key == 'customerName'){
      return true
    }
    else{
      return false
    }
  }
  returnOptionsForTypeAhead = (key:string):string[] =>{
    switch(key){
      case'contractId': return this.props.contract.map(x => `${x.contractId}`) 
      case 'customerOrgId': return this.props.customers.map(x => `${x.kndOrgnr}`) 
      case 'customerName':return this.props.customers.map(x => `${x.kndNamn}`)
      default: return [];
    }
  }
  setCache <T extends object> (rowIndex:number,key:string,value:T[keyof T]){
    const currentCache: InternalMap = { ...this.state.cache };
      if(!currentCache[rowIndex]){
        currentCache[rowIndex] = {}     
      }
      currentCache[rowIndex][key] = value;
      this.setState({cache:currentCache})
  }
  handleSearch = <T extends object>(searchString:string,key:keyof T,index:number) =>{
    console.log(searchString,key) 
    console.log( )
    if(!this.state.cache[index]){
      console.log('finns inte')
      this.setState({cache:{...this.state.cache,[index]:{[key]:searchString}}},()=> console.log(this.state.cache))
    }else{
      this.setState({cache:{...this.state.cache,[index]:{[key]:searchString}}},()=> console.log(this.state.cache))
      //this.setState({cache:{...this.state.cache,[index]:{['searchString']:{[key]:this.state.cache[index]['searchString']:+searchString}}}},()=> console.log(this.state.cache))
    }
  }
_cell <T extends object>(item: T, key:keyof T,readOnly:boolean,index:number):JSX.Element {
  
  const style:React.CSSProperties={height:20,fontSize:'13px !important',padding:'0.3em'}
  const value = item[key];
  const isValidDate = value instanceof Date && !isNaN(value.getTime());
  const handleChange = <K extends keyof T>(e: React.ChangeEvent<HTMLInputElement> | Option[], key: K) => {   
   
      if(this.timerHandler){
        clearTimeout(this.timerHandler)
      }
      let newValue: T[K];
      if(!Array.isArray(e)){
        if (e.target.type === 'checkbox') {
          newValue = e.target.checked as T[K]; 
        } else if (e.target.type === 'date') {
          newValue = new Date(e.target.value) as T[K];
        } else if(e.target.type === 'number'){
          newValue = +e.target.value as T[K]
        } 
        else {
          newValue = e.target.value as T[K];
        }
        this.setCache(index,key as string,newValue)
        this.timerHandler = setTimeout(() => {
          item[key] = newValue
          this.props.callBack(item,index)
          this.setState({cache:{}})
        },500)
      }
      else{
        
      //  this.timerHandler =  setTimeout(() => {
      //   const result:T|undefined = editUtils.UpdateCustomerBasedOnReportType(this.props.invoiceKey,key,buff,this.props.customers,this.props.contract,this.state.cache[index][key as string])
      //   this.setState({cache:{}})
      // },500)
      
       console.log(typeof this.state.cache[index] !== 'undefined' && this.state.cache[index][key as string] )
      
        const buff:T =  structuredClone(item)
        const result:T|undefined = editUtils.UpdateCustomerBasedOnReportType(this.props.invoiceKey,key,buff,this.props.customers,this.props.contract,e)
        if(result !== undefined){
         
        this.props.callBack(result,index,key)
        this.setState({cache:{}})
        }
          
      
        
      }  
    };
  const fields: Record<string, JSX.Element> = {
      bigint: <span>{JSON.stringify(value)}</span>,
      boolean: <input style={style} type="checkbox" checked={typeof this.state.cache?.[index]?.[key as string] == 'undefined' ?  value as boolean : this.state.cache?.[index]?.[key as string]} readOnly={readOnly} />,
      function: <span>Function</span>, 
      number:<label className='fieldWrapper'> <input required={true} type="number" value={typeof this.state.cache?.[index]?.[key as string] == 'undefined' ?  value as number : this.state.cache?.[index]?.[key as string]} readOnly={readOnly} onChange={(e) => handleChange(e,key)}/></label>,
      object: isValidDate ? (
        <label className='fieldWrapper'>
          <input 
          className='testarlite'
            required={true}
            type="date" 
            onChange={(e) => {
              const dateCheck =new Date(e.target.value) 
              if( dateCheck instanceof Date){
                handleChange(e,key)
              } }} 
              onKeyDown={(e) => e.preventDefault()}             
            value={(value as Date).toLocaleDateString() == null ?  '': (value as Date).toLocaleDateString()} 
            readOnly={readOnly}
          />
         
          </label>
        ) : (
          <span></span>
        ),
      string: this.checkKeyForTypeAheds(key as string) ? (
        <label className={`fieldWrapper ${typeof value == 'string' ? value.length == 0 ? 'fieldWrapper-error':'':''}`}>
        {typeof value == 'string' && value.length == 0 &&
           <FontAwesomeIcon
           className="exclamationIconStyle"
             icon={faExclamation}
           />
        }
        <ChoiceInput 
          disabled={readOnly}
          id={`${Math.random()}`}
          options={(typeof this.state.cache[index] !== 'undefined' && this.state.cache[index][key as string]) ? this.returnOptionsForTypeAhead(key as string).filter(x => x.toLowerCase().includes(this.state.cache[index][key as string])) : this.returnOptionsForTypeAhead(key as string)}
          selected={(typeof this.state.cache[index] !== 'undefined' && this.state.cache[index][key as string] ) ?[this.state.cache[index][key as string]] :[item[key]] as Option[] }
          placeholder={typeof this.state.cache[index] !== 'undefined' ? this.state.cache[index][key as string]: item[key] as string}
          onChange={(selected:any) => {
            if(selected.length > 0){
              console.log('nu triggas onChange')
              //this.setState({cache:{}})
                handleChange(selected,key)
            }
             
              
            
          }}
          onInputChange={(text,event) => {
            console.log(text,event)
            console.log('nu triggas onInputChange')
            this.handleSearch<typeof item>(text,key,index)
            
            //handleChange([text],key)
          }
          
          }
        
        />
        </label>
    ): (<label className={this.isErrorClass(key,value)}>
        {typeof value == 'string' && value.length == 0 && 
           <FontAwesomeIcon
           className="exclamationIconStyle"
             icon={faExclamation}
           />
        }
      <input required={true}  type="text" readOnly={readOnly} onChange={(e) => handleChange(e,key)} value={typeof this.state.cache?.[index]?.[key as string] == 'undefined' ?  value as string : this.state.cache?.[index]?.[key as string]} /></label>
    ),
      symbol: <span>Symbol</span>,
      undefined: <span></span>
    };
    
     const fieldType = isValidDate ? 'object' : typeof value;
 
  return (
   <>{fields[fieldType] }</>
  )
}
isErrorClass = (key:any,value:any) => {
    const lalaa = `fieldWrapper ${typeof value == 'string' ? value.length == 0  ? 'fieldWrapper-error':'':''}`
    if(key == 'invoiceIdentifier' || key == 'invoiceReference' || key == 'comment' || key == 'projectEndDate'){
      return 'fieldWrapper'
    }
    else{
      return lalaa
    }  
}
render(){
    const buff = this.props.data.csrdGroup as InternalInvoiceWithCsrdAndGroupId[]/*InvoicesWithCsrdAndGroupId[]*/ | undefined
    const  groupedItems =  buff?.reduce((prev:any, curr:any) => {
          const potentialGroup = prev.find((p:any) => p.groupId == curr?.groupId);
          if(potentialGroup){
            potentialGroup.items = potentialGroup?.items.concat(curr);
          }
          else {
            prev = prev.concat({ 
              ...curr,
              items: [curr]
             })
          }
          return prev;
        }, [] as InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/[]);
    return(
      <div style={{marginTop:20,background:'white',fontSize:'13px'}}>
       
        {this.props.data && this.props.invoiceKey  && (this.props.reportType == 'InvoicereportWithCSRD' || this.props.reportType == 'ProjectreportWithCSRD' )&& this.props.invoiceKey == 'csrdGroup' &&
          <EditableTable 
          columns={
           this.csrdCols
        } 
          grouped={
              {
            groupedColumns: this.groupedColumns,
            collapsedGroups: this.state.collapsedGroups,
            onCollapseGroup: (groupId:number) => { 
            
              const groupIdExists = this.state.collapsedGroups.includes(groupId);
              const newList = groupIdExists ? 
                this.state.collapsedGroups.filter(g => g != groupId)
                :
                this.state.collapsedGroups.concat(groupId);
               this.setState({collapsedGroups:newList})//setCollapsedGroups( newList);
            },
            onCollapseAll: () => {
              if(this.props.data && this.props.invoiceKey){
                const groupNumbers = this.props.data.csrdGroup?.map((inv:any) => inv?.groupId)
                
                .filter((grpId:any, i:any, self:any) => self.indexOf(grpId) == i) as number[];
              if(groupNumbers?.every((grpId:any) => this.state.collapsedGroups.includes(grpId))){
                this.setState({collapsedGroups:[]})//setCollapsedGroups([]);
              }else{
                this.setState({collapsedGroups:groupNumbers})//setCollapsedGroups( groupNumbers ?? [])  
              }
              }
            }
          }}
          
          data={groupedItems ?? []} 
          onChange={(newData) => console.log(newData)}
          />
        }
         {this.props.data && this.props.invoiceKey  && (this.props.reportType == 'Invoicereport' || this.props.reportType == 'Projectreport') && this.props.invoiceKey == 'invoices'  &&
               <EditableTable 
               columns={
               this.invoiceCOls
             } 
               data={this.props.data.invoices as InternalInvoiceEdit[] ?? []} 
               onChange={(newData) => console.log(newData)}
               />          
         }
                
        </div>
    )
  }
}

export{
    EditInvoiceReportTable
}



